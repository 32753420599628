import { stringifyUrl } from "query-string";
import { avatarsApi } from "../../utils/constants";
import { Member, MessageUserData } from "../../utils/types";

export const getMemberAvatarUrl = (
  member: Member | MessageUserData | undefined,
  sizeInPx: number,
  withoutAvatarUrl?: boolean
) =>
  (!withoutAvatarUrl && member?.avatar_url) ||
  stringifyUrl({
    url: member?.name
      ? `${avatarsApi}/initials/${member.name}.svg`
      : `${avatarsApi}/human/${member?.uuid || Math.random()}.svg`,
    query: { w: sizeInPx, h: sizeInPx }
  });
