import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment } from "react";
import { ThemeSelector } from "../../context/appearance";

interface Props {
  open: boolean;
  onClose: () => void;
}

const WorkspaceAppearanceSettingsSidebar: React.FC<Props> = ({
  open,
  onClose
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      static
      className="fixed inset-0 z-20 overflow-hidden"
      open={open}
      onClose={onClose}
    >
      <div className="absolute inset-0 overflow-hidden">
        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <div className="absolute inset-0" onClick={onClose} />
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="relative w-screen max-w-md dark:text-white">
              <div className="max-w-3xl xl:max-w-6xl h-full">
                <div className="h-full flex flex-col bg-white shadow-xl dark:bg-gray-900">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-purple-700 sm:px-6 dark:bg-gray-800">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          Appearance settings
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:bg-gray-800 dark:hover:text-white"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex-1 pt-6 divide-y divide-gray-200 dark:divide-gray-500 overflow-y-auto">
                      <div className="px-4 sm:px-6">
                        <div>
                          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
                            <div>
                              <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                Theme
                              </h3>
                              <ThemeSelector className="mt-2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

export default WorkspaceAppearanceSettingsSidebar;
