import React from "react";

interface Props {
  logo: string;
  title: string;
  website: string;
}

const BrandedHeader: React.FC<Props> = ({ logo, title, website }) => {
  return (
    <a
      className="fixed top-0 left-0"
      href={website}
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex items-center flex-shrink-0 px-4 py-4 text-purple-400 dark:text-white">
        <img src={logo} width="24" height="24" alt="Logo" />
        <div className="ml-2">{title}</div>
      </div>
    </a>
  );
};

export default BrandedHeader;
