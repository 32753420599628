import { User } from ".";
import { fetchApi } from "../../utils/api";

export const isUserLoggedIn = async (): Promise<User | null> => {
  await fetchApi("/sanctum/csrf-cookie");

  const res = await fetchApi("api/auth/is-logged-in");

  if (!res.ok) return null;

  const response = await res.json();

  return response.data;
};

export const logOut = async () => {
  await fetchApi("/logout");
};

export const getDiscordLoginUrl = async (
  searchParams: string
): Promise<string> => {
  const response = await fetchApi(`/login?${searchParams}`);

  if (!response.ok) {
    throw new Error("Failed to fetch the redirect url!");
  }

  const { url } = await response.json();

  return url;
};
