import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

interface ContextValue {
  width: number;
  height: number;
}

const ViewportContext = React.createContext<ContextValue | undefined>(
  undefined
);

const ViewportProvider = ({ children }: PropsWithChildren<{}>) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const value = useMemo(() => ({ width, height }), [width, height]);

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
};

const useViewport = (): ContextValue => {
  const context = useContext(ViewportContext);
  if (context === undefined) {
    throw new Error("useViewport must be used within an ViewportProvider");
  }
  return context;
};

export { useViewport, ViewportProvider };
