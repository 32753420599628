import { Role } from "./constants";
import { CurrencyKey } from "./useCurrency";
import { Network } from "./useNetwork";

export interface EtlHotspot {
  id: string; // helium address
  helium_wallet: string; // helium address
  solana_id: string; // solana address
  solana_wallet: string; // solana address
  last_update: number;
  place: string | null;
  lat: number;
  lng: number;
  location: string; // h3r12
  name: string;
  active: boolean;
  reward_scale: number;
  timestamp_added: number;
  last_assertion: number | null;
  elevation: number;
  gain: number;
  deny_list: boolean;
  lifetime_rewards: number;
  last_24h_rewards: number;
  maker: string; // previously 'payer'. A helium wallet address // TODO: review
  radios: string[];
}

export interface PhpHotspot extends EtlHotspot {
  total_messages: number;
  verified_by: string | null;
}

export type Hotspot = PhpHotspot | EtlHotspot;

export const isPhpHotspot = (hotspot: Hotspot): hotspot is PhpHotspot =>
  "total_messages" in hotspot && typeof hotspot.total_messages === "number";

export interface HotspotSyncStatus {
  id: string;
  synced: boolean;
  synced_since: number; // TODO: rename to 'synced_block_timestamp'
  synced_block: number;
}

export interface HotspotLocation {
  uuid: string;
  created_by: string;
  created_at: string;
  updated_by: string | null;
  updated_at: string;
  name: string;
  address: string | null;
  lat: number;
  lng: number;
  h3_index_r12: string;
  h3_index_r4: string;
  metadata: string | null;
  linked_contact_ids: string[];
  height: number;
}

export interface Hotspots {
  uuid: string;
  address: string;
  altitude: null;
  created_at: string;
  created_by: string;
  current_status: string;
  deleted_at: string | null;
  etl_information: string | null;
  location: string | null;
  maker: string | null;
  name: string;
  sync_at: string | null;
  sync_uuid: string | null;
  total_messages: number;
  updated_at: string;
  updated_by: string | null;
  verified_by: string | null;
  wallet_owner: string | null;
}

export interface Contact {
  uuid: string;
  created_by: string;
  created_at: string;
  updated_by: string | null;
  updated_at: string;
  name: string;
  email: string | null;
  phone: string | null;
  payment_methods: PaymentMethod[];
  linked_location_ids: string[];
  vat: string | null;
  contact_payments: ContactPayment[];
}

export interface Install {
  uuid: string;
  created_by: string;
  created_at: string;
  updated_by: string | null;
  updated_at: string;
  hotspot_uuid: string;
  hotspot: Hotspots[];
  location_uuid: string;
  installed_at: string;
  uninstalled_at: string | null;
  location_lat: number;
  location_lng: number;
  network: Network;
  commissions: InstallCommission[];
}

export interface ContactPayment {
  uuid: string;
  currency: CurrencyKey;
  amount: number;
  created_by: string;
  updated_at: string;
  created_at: string;
  report_commission_uuid: string | null;
  workspace_uuid: string;
  contact_uuid: string;
  context: ContactPaymentContextKey;
  transaction_hash: string | null;
  notes: string | null;
  paid_at: string;
}

export interface Commission {
  uuid: string;
  start_date: string;
  end_date: string;
  currency: string;
  total_commission_amount: number;
  created_at: string;
}

export type WalletVerificationStatus = "unverified" | "pending" | "completed";

export interface WalletVerification {
  status: WalletVerificationStatus;
  donation_wallet: string;
  verification_amount: number;
  expires_at: string;
  verified_at: string | null;
}

export interface Wallet {
  uuid: string;
  address: string;
  created_by: string;
  created_at: string;
  updated_by: string | null;
  updated_at: string;
  verified: boolean;
  tracked: boolean;
  current_verification_status: WalletVerificationStatus;
  verifications: WalletVerification[];
  balance: number;
  hotspot_count: number;
}

export interface Member {
  name: string;
  email: string;
  uuid: string | null;
  avatar_url: string;
  pending: boolean;
  role: Role;
  deleted_at: string | null;
}

export interface Permission {
  label: string;
  description: string;
}

export interface Workspace {
  contact_created_at: string;
  contact_created_by: string;
  contact_email: string;
  contact_id: number;
  contact_name: string;
  contact_phone: string | null;
  contact_updated_at: string;
  contact_updated_by: null;
  contact_uuid: string;
  contact_vat: string | null;
  created_at: string;
  created_by: string;
  name: string;
  timezone: string;
  updated_at: string;
  member_uuid: string;
  uuid: string;
}

export interface GenericFunction<TArgs, TRet> {
  (...args: TArgs[]): TRet | void;
}

export interface DataById<T> {
  [id: string]: T;
}

export type CommentType = "hotspot" | "location" | "contact" | "install"; // TODO: replace with Entity

export interface Comment {
  contact_uuid: string | undefined;
  contact_name: string;
  uuid: string;
  email: string;
  send_at: string;
  user_uuid: string;
  user_name: string;
  created_at: string;
  message: string;
  system: boolean;
  status: string;
  updated_at: string;
  sent_at: string;
  user_email: string;
  user_avatar: null;
}

export interface MessageUserData {
  avatar_url: string;
  name: string;
  provider_nickname: string;
  uuid: string;
}

export interface Message {
  uuid: string;
  message: string;
  status: string;
  payload: string;
  response: string;
  sent_at: string;
  created_at: string;
  updated_at: string;
  user_data: MessageUserData;
}

export type FetchStatus = "fetching" | "ready";

export enum PaymentTypeKey {
  paypal = "paypal",
  iban = "iban",
  walletAddress = "walletAddress",
  venmo = "venmo",
  cashApp = "cashApp",
  cash = "cash",
  wiseByEmail = "wiseByEmail",
  other = "other"
}

export enum BlockchainKey {
  solana = "solana",
  helium = "helium"
}

export interface PaymentMethod {
  contact_id?: number;
  uuid?: string | null;
  name: string;
  type: PaymentTypeKey;
  currency: CurrencyKey;
  metadata: string;
  primary: boolean;
  updated_at?: string | null;
  created_at?: string | null;
  deleted_at?: string | null;
}

export enum CommissionType {
  percentage = "percentage",
  recurringFixed = "recurring-fixed",
  oneTimeFixed = "one-time-fixed"
}

export enum CommissionFrequency {
  monthly = "monthly"
}
export interface InstallCommission {
  uuid?: string | null;
  contact_uuid: string;
  type: CommissionType;
  value: number;
  currency: CurrencyKey;
  template_uuid: string | null;
  start_date: string;
  end_date?: string | null;
  updated_at?: string | null;
  created_at?: string | null;
  deleted_at?: string | null;
  cron_expression?: string | null;
}

export interface CommissionTemplate {
  uuid: string;
  name: string;
  type: CommissionType;
  value: number;
  currency: CurrencyKey;
  primary: boolean;
  workspaceUuid: string;
  order: number;
}
export interface ContactCommission {
  commission: CommissionSubset;
  totalCommissionValue: number;
  install: {
    uuid: string;
    installed_at: string | null;
    uninstalled_at: string | null;
    hotspot: {
      uuid: string;
      name: string;
    };
    location: {
      uuid: string;
      name: string;
    };
  };
}

export interface CommissionValues {
  timestamp: number;
  currency: CurrencyKey;
  amount: number;
  tx_hash: string | null;
  block: number | null;
}

export interface ContactCommissions {
  contact: Pick<Contact, "uuid" | "name">;
  totalCommissionValue: number;
  commissions: ContactCommission[];
}

export interface CommissionReport {
  uuid: string;
  workspaceUuid: string;
  created_at: string;
  created_by: string | null;
  deleted_at: string | null;
  start_date: string; //  "2021-10-01",
  end_date: string; // "2021-10-28",
  timezone: string;
  currency: CurrencyKey;
  total_commission_amount: number; // Total commissions amount for that period in the currency of the report
  report: ContactCommissions[];
  identifier: number;
  current_block: number | null;
  status: "pending" | "completed" | "failed";
  title: string | null;
  contact_payments: ContactPayment[];
}

export type CommissionSubset = Pick<
  InstallCommission,
  | "uuid"
  | "currency"
  | "type"
  | "cron_expression"
  | "value"
  | "start_date"
  | "end_date"
  | "template_uuid"
>;

export enum ContactPaymentContextKey {
  manual = "manual",
  csv = "csv",
  qr = "qr",
  cli = "cli",
  wiseByEmail = "wiseByEmail",
  paypal = "paypal"
}
