import React, { useCallback, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import InfoItems, { InfoItem } from "../../../components/InfoItems";
import { useInstalls } from "../../../context/installs";
import { formatDateToLocale } from "../../../utils/dates";
import { Install } from "../../../utils/types";



const InstallInfoTab: React.FC<RouteComponentProps<{ id: string }>> = (
  props
) => {
  const { installs } = useInstalls();

  const currentInstall = useMemo(
    () => (props.match.params.id ? installs[props.match.params.id] : undefined),
    [installs, props.match.params.id]
  );


  const getInfoItems = useCallback(
    (install: Install) => {
      const {
        installed_at,
        uninstalled_at
      } = install;

      const infoItems: InfoItem[] = [
        
        {
          label: "Installed",
          value: installed_at ? formatDateToLocale(installed_at) : null
        },
        {
          label: "Uninstalled",
          value: uninstalled_at ? formatDateToLocale(uninstalled_at) : null
        },
      ];

      return infoItems;
    },

    [  ]
  );

  return (
    <InfoItems infoItems={currentInstall ? getInfoItems(currentInstall) : []} />
  );
};

export default InstallInfoTab;
