import { find, get, values } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import HeliumDcIcon from "../../components/icons/HeliumDcIcon";
import HeliumIcon from "../../components/icons/HeliumIcon";
import HeliumIotIcon from "../../components/icons/HeliumIotIcon";
import HeliumMobileIcon from "../../components/icons/HeliumMobileIcon";
import SolanaIcon from "../../components/icons/SolanaIcon";
import { BetaApiResponse } from "../../utils/api";
import useCurrency, { CurrencyKey, currencies } from "../../utils/useCurrency";
import useNetwork from "../../utils/useNetwork";

type ContextValue = {
  isLoading: boolean;
  isError: boolean;
  displayInCurrency: (
    amount: number | undefined,
    config?: DisplayInCurrencyConfig
  ) => string;
  getTokenPrice: (currency: CurrencyKey, customToken?: CurrencyKey) => number;
  tokenCurrencyOptions: Array<{ key: string; title: string }>;
};

const TokensContext = React.createContext<ContextValue | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const TokensProvider: React.FC<Props> = (props) => {
  const { currency, getCurrencyTitle, currencyOptions } = useCurrency();
  const { token } = useNetwork();

  const fetchTokenPrices = useCallback(async (): Promise<TokenPricesMap> => {
    const response = await fetch(
      `${process.env.REACT_APP_BETA_API_URL}/api/v1/tokens/prices`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_BETA_API_TOKEN}`
        }
      }
    );

    const apiResponse: BetaApiResponse<TokenPricesMap> = await response.json();

    return apiResponse.data;
  }, []);

  const { data: tokenPrices, error: isError } = useSWR(
    "tokenPrices",
    fetchTokenPrices,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false
    }
  );

  const isLoading = useMemo(
    () => !isError && !tokenPrices,
    [isError, tokenPrices]
  );

  const getTokenPrice = useCallback(
    (currency: CurrencyKey, customToken?: CurrencyKey) =>
      get(tokenPrices, [customToken || token, currency], 0),
    [token, tokenPrices]
  );

  const displayInCurrency = useCallback(
    (
      amount: number | undefined,
      config: DisplayInCurrencyConfig | undefined = {
        withTokenSuffix: false,
        displayCurrency: currency,
        fractionDigits: 2
      }
    ) => {
      if (amount == null) {
        return "...";
      }

      let {
        withTokenSuffix = false,
        displayCurrency = currency,
        fractionDigits
      } = config;

      if (
        [CurrencyKey.mobile, CurrencyKey.iot].includes(displayCurrency) &&
        fractionDigits == null
      ) {
        fractionDigits = 0;
      }

      if (fractionDigits == null) fractionDigits = 2;

      const tokenPrice = getTokenPrice(displayCurrency);

      if (!tokenPrice || withTokenSuffix) {
        return customCurrencyFormatter(
          getCurrencyTitle(displayCurrency),
          amount,
          fractionDigits,
          withTokenSuffix
        );
      }

      return (amount * tokenPrice).toLocaleString("en-US", {
        style: "currency",
        currency: displayCurrency,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
      });
    },
    [currency, getCurrencyTitle, getTokenPrice]
  );

  const tokenCurrencyOptions = useMemo(
    () =>
      currencyOptions.filter(
        (currencyOption) => !!get(tokenPrices, [token, currencyOption.key])
      ),
    [currencyOptions, token, tokenPrices]
  );

  const value = useMemo(
    () => ({
      isLoading,
      isError,
      displayInCurrency,
      getTokenPrice,
      tokenCurrencyOptions
    }),
    [isLoading, isError, displayInCurrency, getTokenPrice, tokenCurrencyOptions]
  );

  return <TokensContext.Provider value={value} {...props} />;
};

export const useTokens = (): ContextValue => {
  const context = useContext(TokensContext);
  if (context === undefined) {
    throw new Error("useTokens must be used within an TokensProvider");
  }
  return context;
};

//
// Utils
//

interface TokenPricesMap {
  [token: string]: {
    [currency: string]: number;
  };
}

// TODO: reconcile with currencyDisplay from useCurrency
const customCurrencyFormatter = (
  label: string,
  amount: number,
  fractionDigits: number,
  withTokenSuffix?: boolean
) => {
  const displayAmount = amount.toLocaleString("en-US", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });

  return withTokenSuffix ? `${displayAmount} ${label}` : displayAmount;
};

interface DisplayInCurrencyConfig {
  withTokenSuffix?: boolean;
  displayCurrency?: CurrencyKey;
  fractionDigits?: number;
}

export interface TokenInfo {
  key: string;
  title: string;
  Icon: any;
  styles: string;
  mintAddress: string;
}

export const tokenIconInfo: {
  [currency: string]: TokenInfo;
} = {
  hnt: {
    key: CurrencyKey.hnt,
    title: currencies[CurrencyKey.hnt].title,
    Icon: HeliumIcon,
    styles: "fill-[#474DFF]",
    mintAddress: "hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux"
  },
  mobile: {
    key: CurrencyKey.mobile,
    title: currencies[CurrencyKey.mobile].title,
    Icon: HeliumMobileIcon,
    styles: "",
    mintAddress: "mb1eu7TzEc71KxDpsmsKoucSSuuoGLv1drys1oP2jh6"
  },
  iot: {
    key: CurrencyKey.iot,
    title: currencies[CurrencyKey.iot].title,
    Icon: HeliumIotIcon,
    styles: "",
    mintAddress: "iotEVVZLEywoTn1QdwNPddxPWszn3zFhEot3MfL9fns"
  },
  dc: {
    key: "dc",
    title: "DC",
    Icon: HeliumDcIcon,
    styles: "",
    mintAddress: "dcuc8Amr83Wz27ZkQ2K9NS6r8zRpf1J6cvArEBDZDmm"
  },
  sol: {
    key: "sol",
    title: "SOL",
    Icon: SolanaIcon,
    styles: "",
    mintAddress: "So11111111111111111111111111111111111111112"
  }
};

export const getTokenInfoByMintAddress = (
  mintAddress: string
): TokenInfo | undefined => find(values(tokenIconInfo), { mintAddress });
