import { capitalize } from "lodash";
import React, { useMemo } from "react";
import TruncatedText from "../../../components/TruncatedText";
import { DropdownSelectOption } from "../../../components/forms/DropdownSelect";
import { getLabelColorStyle } from "../../../components/labels/utils";
import { useAppearance } from "../../../context/appearance";
import { useViewport } from "../../../context/viewport";
import { breakpoints } from "../../../utils/constants";
import {
  BlockchainKey,
  PaymentMethod,
  PaymentTypeKey
} from "../../../utils/types";
import useCurrency from "../../../utils/useCurrency";

interface Props {
  paymentMethod: PaymentMethod;
  withoutDefaultLabel?: boolean;
}

const PaymentMethodComponent: React.FC<Props> = ({
  paymentMethod,
  withoutDefaultLabel
}) => {
  const { isDark } = useAppearance();
  const getCurrencyTitle = useCurrency((state) => state.getCurrencyTitle);
  const { width } = useViewport();
  const isMobile = useMemo(() => width < breakpoints.md, [width]);
  const truncatedLength = useMemo(() => (isMobile ? 10 : 30), [isMobile]);

  const { currency, name, primary, metadata } = useMemo(
    () => paymentMethod,
    [paymentMethod]
  );

  const blockchain: BlockchainKey | undefined = useMemo(() => {
    const { blockchain } = JSON.parse(metadata || "{}");
    return blockchain;
  }, [metadata]);

  return (
    <>
      <div className="flex flex-wrap items-center text-sm font-medium text-gray-900 dark:text-white">
        <div className="mr-2">{name}</div>

        {blockchain && (
          <div
            className="my-1 mr-2 inline-flex items-center rounded-full px-3 text-sm font-medium capitalize"
            style={getLabelColorStyle("#3ad73f", isDark)}
          >
            {blockchainOptions[blockchain].title}
          </div>
        )}

        <div
          className="my-1 mr-2 inline-flex items-center rounded-full px-3 text-sm font-medium"
          style={getLabelColorStyle("#d73aaa", isDark)}
        >
          {getCurrencyTitle(currency)}
        </div>

        {!withoutDefaultLabel && primary && (
          <div
            className="my-1 mr-2 inline-flex items-center rounded-full px-3 text-sm font-medium"
            style={getLabelColorStyle("#7057ff", isDark)}
          >
            default
          </div>
        )}
      </div>

      <div className="mt-1 truncate text-sm font-normal text-gray-900 dark:text-white">
        {renderExtraPaymentTypeInfo(paymentMethod, truncatedLength)}
      </div>
    </>
  );
};

export default PaymentMethodComponent;

//
// Utils
//

export const blockchainOptions: {
  [key in keyof typeof BlockchainKey]: DropdownSelectOption<BlockchainKey>;
} = {
  [BlockchainKey.solana]: {
    key: BlockchainKey.solana,
    title: "Solana"
  },
  [BlockchainKey.helium]: {
    key: BlockchainKey.helium,
    title: "Helium (legacy)"
  }
};

const renderExtraPaymentTypeInfo = (
  paymentMethod: PaymentMethod,
  truncatedLength: number
) => {
  const parsedMetadata = JSON.parse(paymentMethod.metadata || "{}");

  switch (paymentMethod.type) {
    case PaymentTypeKey.walletAddress:
      return (
        <TruncatedText
          text={parsedMetadata.walletAddress}
          label="wallet address"
          truncateLength={truncatedLength}
        />
      );
    case PaymentTypeKey.paypal:
    case PaymentTypeKey.wiseByEmail:
      return (
        <TruncatedText
          text={parsedMetadata.email}
          label={`${capitalize(paymentMethod.type)} email`}
          truncateLength={truncatedLength}
        />
      );
    case PaymentTypeKey.cashApp:
    case PaymentTypeKey.venmo:
      return (
        <TruncatedText
          text={parsedMetadata.handle}
          label="handle"
          truncateLength={truncatedLength}
        />
      );
    case PaymentTypeKey.other:
      return (
        <>
          {parsedMetadata.otherField}:{" "}
          <TruncatedText
            text={parsedMetadata.otherValue}
            label={parsedMetadata.otherField}
            truncateLength={truncatedLength}
          />
        </>
      );
    case PaymentTypeKey.iban:
      return (
        <div className="flex flex-col">
          <TruncatedText
            text={parsedMetadata.iban}
            label="IBAN"
            truncateLength={truncatedLength}
          />
          {parsedMetadata.bic && (
            <TruncatedText
              text={parsedMetadata.bic}
              label="BIC"
              truncateLength={truncatedLength}
            />
          )}
        </div>
      );
    default:
      return null;
  }
};
