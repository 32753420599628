import React from "react";
import classNames from "../utils/classNames";
import { truncateText } from "../utils/text";
import Copy from "./Copy";

interface Props {
  className?: string;
  text: string;
  label: string;
  truncateLength?: number;
}

const TruncatedText: React.FC<Props> = ({
  className,
  text,
  label,
  truncateLength = 20
}) => (
  <div
    className={classNames(
      "text-sm text-gray-900 dark:text-white inline-flex items-center",
      className
    )}
  >
    <pre>{truncateText(text, truncateLength)}</pre>
    <Copy text={text} label={label} className="ml-2 h-4 w-4" />
  </div>
);

export default TruncatedText;
