import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import useAppearanceState from "./useAppearanceState";

type ContextValue = {
  isDark: boolean;
};

const AppearanceContext = React.createContext<ContextValue | undefined>(
  undefined
);

const AppearanceProvider: React.FC<{}> = (props) => {
  const theme = useAppearanceState((state) => state.theme);
  const [isDark, setIsDark] = useState(false);

  const handleWindowUpdate = useCallback(
    (systemDarkPreferenceMediaQuery: { matches: boolean }) => {
      if (
        theme === "dark" ||
        (theme === "system" && systemDarkPreferenceMediaQuery.matches)
      ) {
        setIsDark(true);
        document.documentElement.classList.add("dark");
      } else {
        setIsDark(false);
        document.documentElement.classList.remove("dark");
      }
    },
    [theme]
  );

  useEffect(() => {
    var systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

    if (theme === "dark" || (theme === "system" && systemDarkMode?.matches)) {
      setIsDark(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDark(false);
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    var systemDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

    if (!systemDarkMode?.addEventListener) return;

    systemDarkMode?.addEventListener("change", handleWindowUpdate);

    return () =>
      systemDarkMode?.removeEventListener("change", handleWindowUpdate);
  }, [handleWindowUpdate]);

  const value = useMemo(() => ({ isDark }), [isDark]);

  return <AppearanceContext.Provider value={value} {...props} />;
};

const useAppearance = (): ContextValue => {
  const context = useContext(AppearanceContext);
  if (context === undefined) {
    throw new Error("useAppearance must be used within an AppearanceProvider");
  }
  return context;
};

export { AppearanceProvider, useAppearance };
