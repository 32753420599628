import { isEmpty } from "lodash";
import React from "react";

export interface InfoItem {
  label: any;
  value: any;
}

interface Props {
  infoItems: InfoItem[];
}

const InfoItems: React.FC<Props> = ({ infoItems }) => {
  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        {isEmpty(infoItems) ? (
          <>
            {dummyItems.map((item) => (
              <div key={item} className="sm:col-span-1">
                <dt className="flex w-20 h-3 animate-pulse bg-gray-400 rounded mb-2 mt-1" />
                <dd className="flex w-10 h-3 animate-pulse bg-gray-400 rounded mb-3" />
              </div>
            ))}
          </>
        ) : (
          <>
            {infoItems.map(({ label, value }, index) => (
              <div key={index} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">{label}</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                  {value ?? "-"}
                </dd>
              </div>
            ))}
          </>
        )}
      </dl>
    </div>
  );
};

export default InfoItems;

//
// Utils
//

const dummyItems = ["1", "2", "3", "4"];
