import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

type ContextValue = {
  trackEvent: (
    action: string,
    eventConfig: {
      event_category?: string;
      event_label?: string;
      value?: number;
      method?: string;
    }
  ) => void;
};

const GoogleAnalyticsContext = React.createContext<ContextValue | undefined>(
  undefined
);

const GoogleAnalyticsProvider: React.FC<{}> = (props) => {
  const location = useLocation();

  useEffect(() => {
    if (!window.__ga4React__) return;

    window.__ga4React__.pageview(window.location, document.title);
  }, [location]);

  const trackEvent = useCallback(
    (
      action: string,
      config: {
        event_category?: string;
        event_label?: string;
        value?: number;
      }
    ) => {
      if (!window.__ga4React__) return;

      window.__ga4React__.gtag("event", action, config);
    },
    []
  );

  const value = useMemo(() => ({ trackEvent }), [trackEvent]);

  return <GoogleAnalyticsContext.Provider value={value} {...props} />;
};

const useGoogleAnalytics = (): ContextValue => {
  const context = useContext(GoogleAnalyticsContext);
  if (context === undefined) {
    throw new Error(
      "useGoogleAnalytics must be used within an GoogleAnalyticsProvider"
    );
  }
  return context;
};

export { useGoogleAnalytics, GoogleAnalyticsProvider };
