import { keys } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch
} from "react-router-dom";
import CenteredLoading from "../../components/CenteredLoading";
import { useAppearance } from "../../context/appearance";
import { useViewport } from "../../context/viewport";
import { useWorkspaces } from "../../context/workspaces";
import AppShell from "../../layout/AppShell";
import { breakpoints } from "../../utils/constants";
import usePrevious from "../../utils/usePrevious";
import ContactDetails from "./ContactDetails";
import InstallDetails from "./InstallDetails";
import Map from "./Map";

interface Props extends RouteComponentProps<{}> {}

const MapPage: React.FC<Props> = (props) => {
  const { currentWorkspace } = useWorkspaces();
  const { width } = useViewport();
  const { isDark } = useAppearance();

  const mapContainerRef = useRef<HTMLElement | null>(null);
  const isMobile = useMemo(() => width < breakpoints.md, [width]);
  const detailsPageMatch = matchPath<{
    tab: "info" | "locations" | "contacts" | "installs";
    id: string;
    section: string;
  }>(props.location.pathname, {
    path: `/:tab/:id/:section`,
  });
  const isDetailPage = useMemo(
    () => !!detailsPageMatch?.params.id,
    [detailsPageMatch?.params.id]
  );

  const prevDetailPageId = usePrevious(detailsPageMatch?.params.id);
  useEffect(() => {
    if (
      mapContainerRef.current &&
      detailsPageMatch?.params.id &&
      detailsPageMatch?.params.id !== prevDetailPageId
    ) {
      mapContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [detailsPageMatch, prevDetailPageId]);

  const mapContainerStyle = useMemo(
    () => getMapContainerStyle(isDetailPage, isMobile, isDark),
    [isDetailPage, isMobile, isDark]
  );

  return (
    <>
      {keys(currentWorkspace).shift() ? (
        <AppShell {...props} className="h-screen">
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main
              id="default-page-container"
              className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last"
              tabIndex={0}
              ref={mapContainerRef}
            >
              <Map containerStyle={mapContainerStyle} />
              <Switch>
                <Route path={`/installs/:id`} component={InstallDetails} />
                <Route path={`/contacts/:id`} component={ContactDetails} />
                <Redirect
                  path="/contacts"
                  to={`contacts/${currentWorkspace?.contact_uuid}/info`}
                />
              </Switch>
            </main>
          </div>
        </AppShell>
      ) : (
        <CenteredLoading className="h-52" />
      )}
    </>
  );
};

export default MapPage;

//
// Utils
//

const getMapContainerStyle = (
  isDetailPage: boolean,
  isMobile: boolean,
  isDark: boolean
) => {
  const defaultContainerStyle = {
    backgroundColor: isDark ? "#1f2937" : "#f7f8f8",
  };

  if (isDetailPage) {
    return { ...defaultContainerStyle, height: isMobile ? "20rem" : "60%" };
  }
  return defaultContainerStyle;
};
