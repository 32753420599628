import { Field, FormikHelpers, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { stringifyUrl } from "query-string";
import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import BrandedHeader from "../../components/BrandedHeader";
import FormProvider from "../../components/forms/FormProvider";
import Input from "../../components/forms/Input";
import { ApiResponse, fetchApi } from "../../utils/api";

export const UpdateEmail: React.FC<RouteComponentProps> = (props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onSubmit = useCallback(
    async ({ email }: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      setErrorMessage(null);

      const response = await fetchApi(`/api/verification-code`, {
        method: "POST",
        body: JSON.stringify({ email }),
      });

      const apiResponse: ApiResponse<[]> = await response.json();

      formikHelpers.setSubmitting(false);

      if (apiResponse.status) {
        localStorage.setItem("@host/email", email);
        props.history.push(
          stringifyUrl({
            url: "/auth/email/verify-code",
          })
        );

        return;
      }

      setErrorMessage("Something went wrong");
    },
    [props.history]
  );

  return (
    <>
      <BrandedHeader
        logo="/favicon-32x32.png"
        title="Hotspotty Host Dashboard"
        website="https://hotspotty.net"
      />
      <div
        className="flex flex-col items-center justify-center"
        style={{
          minHeight: "calc(100vh - 49px)",
          maxHeight: "calc(100vh - 49px)",
        }}
      >
        <MessageBox
          title={
            <span className="text-purple-400 dark:text-white">
              Please enter your email address to continue.
            </span>
          }
          message={
            <div className="w-full mt-4 ">
              <FormProvider
                error={errorMessage}
                submitButtonText="Continue"
                submittingSubmitButtonText="sending..."
                classnames={{
                  form: "bg-gray-50 dark:bg-gray-800 p-4 sm:p-0 rounded-md",
                  buttonWrapper: "pb-0 pr-0",
                }}
                formikProps={{
                  onSubmit,
                  initialValues: { email: "" },
                  validationSchema: Yup.object().shape({
                    email: Yup.string()
                      .email("Please enter a valid email address")
                      .required("Required"),
                  }),
                }}
                orderedFieldNames={ORDERED_FIELD_NAMES}
              >
                {({ isSubmitting }: FormikProps<FormValues>) => {
                  return (
                    <Field
                      id="search_field"
                      name="email"
                      label="Email"
                      placeholder="Add your email"
                      type="email"
                      classnames={{ wrapper: "w-full" }}
                      component={Input}
                      disabled={isSubmitting}
                    />
                  );
                }}
              </FormProvider>
            </div>
          }
        />
      </div>
    </>
  );
};

//
// Utils
//

interface FormValues {
  email: string;
}

const ORDERED_FIELD_NAMES = ["email"];

export const MessageBox = ({
  title,
  message,
  header,
  footerItems,
}: {
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  header?: React.ReactNode;
  footerItems?: React.ReactNode[];
}) => (
  <div className="sm:shadow sm:rounded-lg max-w-md">
    <div className="sm:bg-gray-50 sm:dark:bg-gray-800 sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        {header}

        {title && (
          <h3 className="text-lg leading-6 text-gray-900 dark:text-white">
            {title}
          </h3>
        )}

        {message && (
          <div className="mt-2 max-w-xl text-sm text-gray-500 dark:text-gray-300">
            {message}
          </div>
        )}

        {footerItems && !isEmpty(footerItems) && (
          <div className="flex mt-6 space-x-4">
            <div className="flex-1" />
            {footerItems.map((Item, idx) => (
              <div key={idx}>{Item}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);
