import React from "react"

interface Props {
  className?: string
  style?: React.CSSProperties
}

const HeliumDcIcon: React.FC<Props> = (props) => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M40.2119 20.054C40.2119 31.0997 31.2576 40.054 20.2119 40.054C9.16622 40.054 0.211914 31.0997 0.211914 20.054C0.211914 9.00826 9.16622 0.0539551 20.2119 0.0539551C31.2576 0.0539551 40.2119 9.00826 40.2119 20.054Z"
      fill="#464E6A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6344 20.054C14.6344 18.0695 13.0317 16.4382 10.9809 16.2493C10.7616 16.2291 10.5825 16.0626 10.5825 15.8548V12.1096C10.5825 11.9017 10.7615 11.7324 10.9816 11.7416C15.6671 11.9385 19.4017 15.5847 19.4017 20.054C19.4017 24.5232 15.6671 28.1694 10.9816 28.3663C10.7615 28.3755 10.5825 28.2062 10.5825 27.9983V24.2532C10.5825 24.0453 10.7616 23.8788 10.9809 23.8586C13.0317 23.6698 14.6344 22.0385 14.6344 20.054Z"
      fill="#009FF9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7893 20.0539C25.7893 22.0384 27.392 23.6697 29.4428 23.8586C29.662 23.8788 29.8411 24.0453 29.8411 24.2531V27.9983C29.8411 28.2062 29.6622 28.3755 29.4421 28.3663C24.7566 28.1694 21.022 24.5232 21.022 20.0539C21.022 15.5847 24.7566 11.9385 29.4421 11.7416C29.6622 11.7324 29.8411 11.9017 29.8411 12.1096V15.8548C29.8411 16.0626 29.662 16.2291 29.4428 16.2493C27.392 16.4381 25.7893 18.0695 25.7893 20.0539Z"
      fill="#27EE76"
    />
  </svg>
)

export default HeliumDcIcon
