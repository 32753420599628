import animalHash from "angry-purple-tiger";
import { stringifyUrl } from "query-string";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import StatusDot from "../../../../components/StatusDot";
import { getLabelColorStyle } from "../../../../components/labels/utils";
import { useAppearance } from "../../../../context/appearance";
import { useHotspots } from "../../../../context/hotspots";
import { ContactInstallCommission } from "../../../../context/installs/utils";
import { avatarsApi } from "../../../../utils/constants";
import { formatDateToLocale } from "../../../../utils/dates";
import { CommissionType, InstallCommission } from "../../../../utils/types";
import useCurrency from "../../../../utils/useCurrency";

interface Props {
  items: ContactInstallCommission[];
}

const ContactCommissionsTable: React.FC<Props> = ({ items }) => {
  const { isDark } = useAppearance();
  const { hotspotsMap } = useHotspots();
  const { getCurrencyTitle } = useCurrency();

  const getCommissionValueLabel = useCallback(
    ({
      currency,
      type,
      value
    }: Pick<InstallCommission, "currency" | "type" | "value">) => {
      let labelText = value.toString();

      if (type === CommissionType.percentage) {
        labelText += "%";
      }

      labelText += " " + getCurrencyTitle(currency);

      return labelText;
    },
    [getCurrencyTitle]
  );

  const renderTableRow = useCallback(
    ({ commission, install }: ContactInstallCommission) => {
      const { uuid, hotspot_uuid } = install;

      return (
        <tr key={uuid}>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link
              className="relative flex items-center space-x-3 group text-sm font-medium text-gray-900 dark:text-white"
              to={`/installs/${uuid}/info`}
            >
              <div className="flex-shrink-0">
                <span className="inline-block relative">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={stringifyUrl({
                      url: `${avatarsApi}/bottts/${hotspot_uuid}.svg`,
                      query: { w: 40, h: 40 }
                    })}
                    alt="Hotspot avatar"
                  />
                  {hotspot_uuid && (
                    <StatusDot
                      hotspot={hotspotsMap[hotspot_uuid]}
                      className="absolute top-0 right-0 block"
                    />
                  )}
                </span>
              </div>
              <div className="flex-1 min-w-0 focus:outline-none">
                <div>
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                    {animalHash(hotspot_uuid)}
                  </p>
                </div>
                <div className="flex items-center flex-wrap">
                  <div
                    className="inline-flex items-center rounded-full font-medium px-3 text-sm mt-2"
                    style={getLabelColorStyle("#d73aaa", isDark)}
                  >
                    {getCommissionValueLabel(commission)}
                  </div>
                </div>
              </div>
            </Link>
          </td>

          <td className="px-6 py-4 whitespace-nowrap">
            {install.uninstalled_at
              ? `${formatDateToLocale(
                  install.installed_at
                )} - ${formatDateToLocale(install.uninstalled_at)}`
              : `from ${formatDateToLocale(install.installed_at)}`}
          </td>

          <td className="px-6 py-4 whitespace-nowrap">
            {getCommissionPeriodText(commission)}
          </td>
        </tr>
      );
    },
    [getCommissionValueLabel, hotspotsMap, isDark]
  );

  return (
    <div className="shadow w-full overflow-x-auto sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
        <thead className="bg-gray-50 text-gray-500 dark:bg-gray-700 dark:text-gray-300">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              <div className="flex items-center">
                <span className="mr-1">
                  {items.length} commission
                  {items.length > 1 ? "s" : ""}
                </span>
              </div>
            </th>

            <th
              scope="col"
              style={{ minWidth: "7rem" }}
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              Installation period
            </th>

            <th
              scope="col"
              style={{ minWidth: "7rem" }}
              className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
            >
              Commission period
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-gray-500 dark:text-gray-50 dark:divide-gray-500 dark:bg-gray-800">
          {items.map(renderTableRow)}
        </tbody>
      </table>
    </div>
  );
};

export default ContactCommissionsTable;

//
// Utils
//

export const getCommissionPeriodText = (commission: InstallCommission) => {
  if (commission.type === CommissionType.oneTimeFixed) {
    return formatDateToLocale(commission.start_date);
  }

  const text = formatDateToLocale(commission.start_date);

  return commission.end_date
    ? `${text} - ${formatDateToLocale(commission.end_date)}`
    : `from ${text}`;
};
