import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ApiResponse, fetchApi } from "../../utils/api";
import { Comment, CommentType } from "../../utils/types";
import usePrevious from "../../utils/usePrevious";
import { useWorkspaces } from "../workspaces";

interface Props {}

interface CommentsMap {
  [uuid: string]: Comment[];
}

export type ContextValue = {
  comments: CommentsMap;
  fetchComments: (typeUuid: string) => Promise<void>;
  addComment: (
    message: string,
    type: CommentType,
    typeUuid: string
  ) => Promise<ApiResponse<Comment>>;
};

export const CommentsContext = React.createContext<ContextValue | undefined>(
  undefined
);

export const CommentsProvider: React.FC<Props> = (props) => {
  const { currentWorkspace } = useWorkspaces();
  const [comments, setComments] = useState<CommentsMap>({});

  const fetchComments = useCallback(
    async (typeUuid: string) => {
      const response = await fetchApi(
        `/api/workspace/${currentWorkspace?.uuid}/comments/${currentWorkspace?.contact_uuid}`
      );

      const apiResponse: ApiResponse<Comment[]> = await response.json();

      if (apiResponse.status) {
        setComments((prevState) => ({
          ...prevState,
          [typeUuid]: apiResponse.data
        }));
      }
    },
    [currentWorkspace?.contact_uuid, currentWorkspace?.uuid]
  );

  useEffect(() => {
    if (currentWorkspace?.contact_uuid) {
      fetchComments(currentWorkspace?.contact_uuid);
    }
  }, [currentWorkspace?.contact_uuid, fetchComments]);

  const addComment = useCallback(
    async (message: string, type: CommentType, typeUuid: string) => {
      try {
        const response = await fetchApi(
          `/api/workspace/${currentWorkspace?.uuid}/comments/${currentWorkspace?.contact_uuid}`,
          {
            method: "POST",
            body: JSON.stringify({ message, type, type_uuid: typeUuid })
          }
        );

        const apiResponse: ApiResponse<Comment> = await response.json();

        await fetchComments(typeUuid);

        return apiResponse;
      } catch {
        throw Error("Something went wrong");
      }
    },
    [currentWorkspace?.uuid, currentWorkspace?.contact_uuid, fetchComments]
  );

  const prevContactUuid = usePrevious(currentWorkspace?.contact_uuid);
  useEffect(() => {
    if (
      currentWorkspace?.contact_uuid &&
      prevContactUuid &&
      currentWorkspace.contact_uuid !== prevContactUuid
    ) {
      setComments({});
    }
  }, [currentWorkspace, prevContactUuid]);

  const value = useMemo(
    () => ({
      comments,
      fetchComments,
      addComment
    }),
    [comments, fetchComments, addComment]
  );

  return <CommentsContext.Provider value={value} {...props} />;
};

export const useComments = (): ContextValue => {
  const context = useContext(CommentsContext);

  if (context === undefined) {
    throw new Error("useComments must be used within an CommentsProvider");
  }

  return context;
};
