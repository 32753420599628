import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ApiResponse, fetchApi } from "../../utils/api";
import { Role } from "../../utils/constants";
import {
  Comment,
  ContactPayment,
  Member,
  PaymentMethod
} from "../../utils/types";
import { CurrencyKey } from "../../utils/useCurrency";
import usePrevious from "../../utils/usePrevious";
import { useWorkspaces } from "../workspaces";

type ContextValue = {
  contactDetails: ContactDetails | null;
  member: Member;
  fetchContactAvatar: (uuid: string) => Promise<{
    avatar_url: string;
  }>;
};

const ContactsContext = React.createContext<ContextValue | undefined>(
  undefined
);

interface Props {}

export const ContactsProvider: React.FC<Props> = (props) => {
  const { currentWorkspace } = useWorkspaces();
  const [contactDetails, setContactDetails] = useState<ContactDetails | null>(
    null
  );

  const member: Member = useMemo(
    () => ({
      name: currentWorkspace?.contact_name || "",
      email: currentWorkspace?.contact_email || "",
      uuid: currentWorkspace?.member_uuid || "",
      avatar_url: "",
      pending: false,
      role: Role.member,
      deleted_at: ""
    }),
    [
      currentWorkspace?.contact_email,
      currentWorkspace?.contact_name,
      currentWorkspace?.member_uuid
    ]
  );

  const fetchContactDetails = useCallback(async () => {
    if (!currentWorkspace) return;

    const res = await fetchApi(
      `/api/contact/${currentWorkspace?.contact_uuid}`
    );

    const apiResponse: ApiResponse<ContactDetails> = await res.json();

    if (apiResponse.status) {
      setContactDetails(apiResponse.data);
    }
  }, [currentWorkspace]);

  const fetchContactAvatar = useCallback(async (uuid: string) => {
    const res = await fetchApi(`/api/avatar/${uuid}`);

    const apiResponse: ApiResponse<{ avatar_url: string }> = await res.json();

    return apiResponse.data;
  }, []);

  useEffect(() => {
    fetchContactDetails();
  }, [fetchContactDetails]);

  const prevContactUuid = usePrevious(currentWorkspace?.contact_uuid);
  useEffect(() => {
    if (
      currentWorkspace?.contact_uuid &&
      prevContactUuid &&
      currentWorkspace.contact_uuid !== prevContactUuid
    ) {
      setContactDetails(null);
    }
  }, [currentWorkspace, prevContactUuid]);

  const value = useMemo(
    () => ({
      contactDetails,
      member,
      fetchContactAvatar
    }),
    [contactDetails, member, fetchContactAvatar]
  );

  return <ContactsContext.Provider value={value} {...props} />;
};

export const useContacts = (): ContextValue => {
  const context = useContext(ContactsContext);
  if (context === undefined) {
    throw new Error("useContacts must be used within an ContactsProvider");
  }
  return context;
};

//
// Utils
//

export interface ContactValues {
  name: string;
  email: string | null;
  phone: string | null;
  vat: string | null;
  payment_methods: PaymentMethod[] | null;
}

interface ContactDetails {
  comments: Comment[];
  contact_payments: ContactPayment[];
  created_at: string;
  created_by: string;
  email: string;
  id: number;
  linked_location_ids: string[];
  name: string;
  payment_methods: PaymentMethod[];
  payment_methods_available: {
    currency: CurrencyKey;
    metadata: string;
    type: string;
    uuid: string;
  };
  phone: string;
  updated_at: string;
  updated_by: string;
  uuid: string;
  vat: string;
}
