import moment from "moment";
import { Install, InstallCommission } from "../../utils/types";

export const isActiveInstall = ({
  installed_at,
  uninstalled_at,
}: Partial<Install>) =>
  moment(installed_at).utc().isBefore(moment().utc()) &&
  ((uninstalled_at && moment(uninstalled_at).utc().isAfter(moment().utc())) ||
    !uninstalled_at);
  export interface ContactInstallCommission {
    install: Install;
    commission: InstallCommission;
  }
  
  export const getContactInstallCommissions = (
    contactUuid: string | number,
    installs: Install[]
  ) => {
    const items: ContactInstallCommission[] = [];
  
    installs.forEach((install) => {
      install.commissions.forEach((commission) => {
        if (commission.contact_uuid === contactUuid) {
          items.push({ install, commission });
        }
      });
    });
  
    return items;
  };
