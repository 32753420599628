import { Transition } from "@headlessui/react";
import { values } from "lodash";
import React, { useMemo, useState } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import AppearanceSettingsButton from "../components/appearanceSettings/AppearanceSettingsButton";
import CenteredLoading from "../components/CenteredLoading";
import MenuIcon from "../components/icons/MenuIcon";
import WorkspaceButton from "../components/WorkspaceButton";
import { useWorkspaces } from "../context/workspaces";
import classNames from "../utils/classNames";

interface Props extends RouteComponentProps<{}> {
  currentPage?: string | React.ReactNode;
  isLoading?: boolean;
  className?: string;
}

const AppShell: React.FC<Props> = ({
  className,
  children,
  isLoading,
  currentPage
}) => {
  const { workspaces, currentWorkspace, setCurrentWorkspace } = useWorkspaces();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const history = useHistory();
  const toggleSideMenu = () =>
    setShowSideMenu((prevShowSideMenu) => !prevShowSideMenu);

  const renderSideMenuContent = useMemo(
    () => (
      <>
        <div className="flex items-center flex-shrink-0 justify-between px-4 py-1">
          <Link to="/">
            <img
              src="/favicon-32x32.png"
              width="24"
              height="24"
              alt="Hotspotty logo"
            />
          </Link>

          <WorkspaceButton />
        </div>
        <div className="h-0 flex-1 overflow-y-auto">
          <nav className="px-3 mt-6">
            <div className="pb-8 space-y-1">
              {values(workspaces).map((item) => (
                <div
                  key={item.contact_uuid}
                  onClick={() => {
                    setCurrentWorkspace(item);
                    history.push(`/contacts/${item.contact_uuid}/info`);
                    if (showSideMenu) toggleSideMenu();
                  }}
                  className={classNames(
                    "flex items-center px-2 py-2 text-sm font-medium rounded-md",
                    currentWorkspace?.uuid === item.uuid &&
                      currentWorkspace.contact_uuid === item.contact_uuid
                      ? "bg-gray-200 text-gray-900 dark:text-white dark:bg-gray-700 cursor-pointer"
                      : "text-gray-500 hover:text-gray-900 hover:bg-gray-50 group dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-transparent cursor-pointer"
                  )}
                >
                  {item.name}
                </div>
              ))}
            </div>
          </nav>
        </div>
      </>
    ),
    [
      workspaces,
      currentWorkspace?.uuid,
      currentWorkspace?.contact_uuid,
      setCurrentWorkspace,
      history,
      showSideMenu
    ]
  );

  const renderBreadcrumbs = useMemo(
    () => (
      <div className="flex-1 flex items-center h-full whitespace-nowrap overflow-auto lg:overflow-visible">
        <div className="lg:mx-4 text-sm font-medium text-gray-500">
          {currentPage}
        </div>
      </div>
    ),
    [currentPage]
  );

  return (
    <div
      className={classNames(
        "sm:h-screen flex overflow-hidden bg-white dark:bg-gray-900 dark:text-white",
        className
      )}
    >
      <Transition show={showSideMenu}>
        <div className="lg:hidden">
          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0"
            >
              <div
                className="absolute inset-0 bg-gray-600 opacity-75"
                onClick={toggleSideMenu}
              />
            </Transition.Child>

            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
              className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-white focus:outline-none dark:bg-gray-900"
            >
              {renderSideMenuContent}
            </Transition.Child>

            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </div>
      </Transition>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        {/* Navbar in mobile */}
        <div className="lg:hidden flex items-center justify-between bg-gray-50 border-b border-gray-200 dark:bg-gray-900 dark:border-gray-800">
          <button
            type="button"
            className="flex-none h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 dark:text-gray-200 focus:outline-none dark:hover:text-white"
            onClick={toggleSideMenu}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" />
          </button>

          {renderBreadcrumbs}

          <AppearanceSettingsButton />
        </div>

        {/* Navbar in desktop */}
        <div className="hidden lg:flex bg-gray-50 border-b border-gray-200 flex-shrink-0 justify-between dark:bg-gray-900 dark:border-gray-800">
          <div className="flex items-center flex-shrink-0 px-4 py-1 space-x-2">
            <Link to="/">
              <img
                src="/favicon-32x32.png"
                width="24"
                height="24"
                alt="Hotspotty logo"
              />
            </Link>

            {values(workspaces).map((item) => (
              <div
                key={item.contact_uuid}
                onClick={() => {
                  setCurrentWorkspace(item);
                  history.push(`/contacts/${item.contact_uuid}/info`);
                  if (showSideMenu) toggleSideMenu();
                }}
                className={classNames(
                  "flex items-center px-3 py-1 text-sm font-medium rounded-md",
                  currentWorkspace?.uuid === item.uuid &&
                    currentWorkspace.contact_uuid === item.contact_uuid
                    ? "bg-gray-200 text-gray-900 dark:bg-gray-700 dark:text-white cursor-pointer"
                    : "text-gray-500 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-transparent cursor-pointer"
                )}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className="mr-4 flex items-center">
            <WorkspaceButton className="mx-1" />
            <AppearanceSettingsButton />
          </div>
        </div>

        {isLoading ? <CenteredLoading className="h-full" /> : children}
      </div>
    </div>
  );
};

export default AppShell;
