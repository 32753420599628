export type ApiResponse<T> =
  | {
      status: true;
      data: T;
      message: string;
    }
  | {
      status: false;
      data: T;
      message: string;
      errorCode: string | null;
    };

const getUrlForApi = (path: string): URL => {
  const location = process.env.REACT_APP_APP_API;

  //The regexp below removes trailing slashes in case the path: starts with / and has something else after is.
  const pathname = `/${path.replace(/^\/(?=.+)/, "")}`;

  return new URL(pathname, location);
};

export const fetchApi = (path: string, options?: RequestInit) => {
  const rewrittenPath = getUrlForApi(path);

  const enhancedOptions: RequestInit = {
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options?.headers
    },
    credentials: "include"
  };

  return fetch(rewrittenPath.toString(), enhancedOptions);
};

export interface BetaApiResponse<T> {
  object: string;
  url: string;
  data: T;
}
