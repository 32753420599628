import { useEffect, useState } from "react";
import {
  Boundaries,
  Coordinates,
  isPointInsideBoundaries
} from "./useMapCenter";

export const useShouldUpdateMapCentroid = ({
  selectedItem,
  mapBoundaries,
  currentMapCentroid
}: {
  selectedItem: {
    id: string;
    coordinates: Coordinates;
  };
  mapBoundaries?: Boundaries;
  currentMapCentroid: Coordinates;
}) => {
  const [itemId, setItemId] = useState<string>("");
  const [mapCentroid, setMapCentroid] = useState<Coordinates>([
    ...currentMapCentroid
  ]);

  useEffect(() => {
    const [longitude, latitude] = selectedItem.coordinates;

    if (!latitude || !longitude) return;

    if (itemId === selectedItem.id) return;

    setItemId(selectedItem.id || "");

    const shouldChangeCenter =
      !mapBoundaries ||
      !itemId ||
      !isPointInsideBoundaries([longitude, latitude], mapBoundaries);

    if (!shouldChangeCenter) return;

    setMapCentroid([longitude, latitude]);
  }, [selectedItem.coordinates, selectedItem.id, itemId, mapBoundaries]);

  return mapCentroid;
};
