import produce from "immer";
import create from "zustand";
import { persist } from "zustand/middleware";

export const validColor = "#32c48d";
export const invalidColor = "#E86161";

export enum VisualizationLayer {
  hotspots = "hotspots",
  locations = "locations",
  satellite = "satellite"
}

type MapConfig = {
  visibleLayers: { [layer in keyof typeof VisualizationLayer]: boolean };
  toggleVisibleLayer: (layer: VisualizationLayer) => void;
};

const useMapConfig = create<MapConfig>(
  persist(
    (set, get) => ({
      visibleLayers: {
        [VisualizationLayer.hotspots]: true,
        [VisualizationLayer.locations]: true,
        [VisualizationLayer.satellite]: false
      },
      toggleVisibleLayer: (layer) =>
        set((state) =>
          produce(state, (draft) => {
            draft.visibleLayers[layer] = !draft.visibleLayers[layer];
          })
        )
    }),
    {
      name: "map_config",
      version: 9
    }
  )
);

export default useMapConfig;
