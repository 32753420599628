import { values } from "lodash";
import React, { useEffect, useMemo } from "react";
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from "react-router-dom";
import NavLink from "../../../components/NavLink";
import { useComments } from "../../../context/comments";
import { useContacts } from "../../../context/contacts";
import { useInstalls } from "../../../context/installs";
import { getContactInstallCommissions } from "../../../context/installs/utils";
import MemberAvatarImg from "../../../context/members/MemberAvatarImg";
import { useWorkspaces } from "../../../context/workspaces";
import useMapCenter, {
  Boundaries,
  Coordinates,
} from "../../../utils/useMapCenter";
import { useShouldUpdateMapCentroid } from "../../../utils/useShouldCenterMap";
import ContactCommentsTab from "./ContactCommentsTab";
import ContactCommissionsTab from "./ContactCommissionsTab";
import ContactInfoTab from "./ContactInfoTab";
import ContactPaymentsTab from "./ContactPaymentsTab";

interface Props extends RouteComponentProps<{ id: string }> {}

const ContactDetails: React.FC<Props> = (props) => {
  const { currentWorkspace } = useWorkspaces();
  const { activeInstallsForCurrentContact } = useInstalls();
  const { comments } = useComments();
  const { contactDetails, member } = useContacts();
  const setCenter = useMapCenter((state) => state.setCenter);
  const center = useMapCenter((state) => state.center);
  const boundaries = useMapCenter((state) => state.boundaries);

  const mapCentroidConfig: {
    selectedItem: {
      id: string;
      coordinates: Coordinates;
    };
    mapBoundaries?: Boundaries;
    currentMapCentroid: Coordinates;
  } = useMemo(() => {
    let pointDetails = { lng: 0, lat: 0, id: "" };

    return {
      selectedItem: {
        coordinates: [pointDetails.lng, pointDetails.lat],
        id: pointDetails.id,
      },
      currentMapCentroid: center,
      mapBoundaries: boundaries,
    };
  }, [boundaries, center]);

  const mapCentroid = useShouldUpdateMapCentroid(mapCentroidConfig);

  useEffect(() => {
    setCenter(mapCentroid);
  }, [mapCentroid, setCenter]);

  const paymentsCount = useMemo(() => {
    if (!contactDetails) {
      return 0;
    }

    return contactDetails.contact_payments.length;
  }, [contactDetails]);

  const messagesCount = useMemo(() => {
    if (!currentWorkspace?.contact_uuid) {
      return 0;
    }

    if (!comments) {
      return 0;
    }

    return values(comments[currentWorkspace?.contact_uuid]).length;
  }, [comments, currentWorkspace?.contact_uuid]);

  const commissionsCount = useMemo(() => {
    const activeInstallContactCommissions = getContactInstallCommissions(
      `${currentWorkspace?.contact_uuid}`,
      activeInstallsForCurrentContact
    );

    return activeInstallContactCommissions.length;
  }, [activeInstallsForCurrentContact, currentWorkspace?.contact_uuid]);

  const tabs = useMemo(
    () => [
      { key: "info", title: "Info", count: 0 },
      {
        key: "commissions",
        title: "Commissions",
        count: commissionsCount,
      },
      {
        key: "payments",
        title: "Payments",
        count: paymentsCount,
      },
      {
        key: "messages",
        title: "Messages",
        count: messagesCount,
      },
    ],
    [commissionsCount, messagesCount, paymentsCount]
  );

  const detailsPageMatch = matchPath<{
    id: string;
    section: string;
  }>(props.location.pathname, {
    path: `/contacts/:id/:section`,
  });

  const renderMemberAvatar = useMemo(
    () => (
      <MemberAvatarImg
        member={member}
        sizeInPx={120}
        contactUuid={currentWorkspace?.contact_uuid}
      />
    ),
    [currentWorkspace?.contact_uuid, member]
  );

  return (
    <>
      <div className="pb-6">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16">
            <div className="flex justify-between">
              <div className="h-24 w-24 sm:h-32 sm:w-32 z-10 rounded-full">
                {renderMemberAvatar}
              </div>
            </div>
            <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1">
                <div className="flex justify-between">
                  {contactDetails ? (
                    <h1 className="text-md sm:text-2xl font-bold text-gray-900 dark:text-white truncate inline-flex items-center">
                      <span>{currentWorkspace?.contact_name}</span>
                    </h1>
                  ) : (
                    <span className="flex w-20 h-6 animate-pulse bg-gray-400 rounded sm:w-56 mt-2" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="sm:border-b sm:border-gray-200 dark:border-gray-500 overflow-auto whitespace-nowrap">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <select
                className="sm:hidden block w-full pl-3 py-2 sm:text-sm rounded-md dark:bg-gray-800"
                onChange={(e) => {
                  props.history.push(`${props.match.url}/${e.target.value}`);
                }}
                value={detailsPageMatch?.params.section}
              >
                {tabs.map(({ key, title }) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </select>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map(({ key, title, count }) => (
                    <NavLink
                      key={key}
                      to={`${props.match.url}/${key}`}
                      activeClassName="border-hotspotty-400 text-gray-900 dark:text-white dark:text-white dark:border-white"
                      inactiveClassName="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300"
                      className="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    >
                      <span>{title}</span>
                      {!!count && (
                        <span className="bg-gray-200 text-gray-500 group-hover:text-gray-700 dark:text-gray-200 dark:bg-gray-600 dark:group-hover:text-gray-200 dark:group-hover:bg-gray-500 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {count}
                        </span>
                      )}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route
            path={`${props.match.path}/info`}
            component={ContactInfoTab as any}
          />
          <Route
            path={`${props.match.path}/commissions`}
            component={ContactCommissionsTab as any}
          />
          <Route
            path={`${props.match.path}/payments`}
            component={ContactPaymentsTab as any}
          />
          <Route
            path={`${props.match.path}/messages`}
            component={ContactCommentsTab as any}
          />
          <Redirect to={`${props.match.path}/info`} />
        </Switch>
      </div>
    </>
  );
};

export default ContactDetails;
