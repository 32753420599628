import { isEmpty } from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import ModalWarning from "../../components/modals/ModalWarning";
import Toast from "./Toast";

// TODO: add push notifications https://developers.google.com/web/ilt/pwa/introduction-to-push-notifications

export interface Notification {
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  to?: string;
  waitForInteraction?: boolean;
  type: "info" | "success" | "warning" | "error";
}

interface ProviderProps {
  delay: number; // Delay hiding the toast (ms)
}

interface ContextValue {
  addToast: (notification: Notification) => void;
  modalWarningConfig: ModalConfig;
  setModalWarningConfig: React.Dispatch<React.SetStateAction<ModalConfig>>;
}

const ToastContext = React.createContext<ContextValue | undefined>(undefined);

const ToastProvider = ({
  children,
  delay
}: PropsWithChildren<ProviderProps>) => {
  const [toasts, setToasts] = useState<
    Array<
      {
        id: string;
      } & Notification
    >
  >([]);
  const [modalWarningConfig, setModalWarningConfig] = useState<ModalConfig>({
    open: false
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const toastsWithAutoclose = toasts.filter((t) => !t.waitForInteraction);

    if (!isEmpty(toastsWithAutoclose)) {
      timer = setTimeout(() => setToasts(toastsWithAutoclose.slice(1)), delay);
    }

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [toasts, delay]);

  const addToast = useCallback((notification: Notification) => {
    setToasts((toasts) => [
      ...toasts,
      {
        id: generateToastId(),
        ...notification
      }
    ]);
  }, []);

  /*
  The code below will show a notification when opening the app for the first time.
  This is useful for when we have critical messages such as when the blockchain is outdated.

  useEffect(() => {
    // setModalWarningConfig({
    //   open: true,
    //   title: "Blockchain issues",
    //   message:
    //     "The Blockchain is currently halted to increase disk space, the system is working normally but no new blocks are currently added. We will resume block production once the new disk is fixed.",
    //   confirmButtonText: "Got it",
    //   confirmButtonClassname:
    //     "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 md:ml-3 sm:w-auto sm:text-sm",
    //   withoutCancelButton: true
    // });

    addToast({
      type: "warning",
      waitForInteraction: true,
      message:
        "The Blockchain data is currently behind but syncing slowly and everything should get back to normal soon."
    });
  }, [addToast]);
  */

  const isToastShowing = useCallback(
    (id: string) => {
      return toasts.some((t) => t.id === id);
    },
    [toasts]
  );

  const value = useMemo(
    () => ({ addToast, modalWarningConfig, setModalWarningConfig }),
    [addToast, modalWarningConfig, setModalWarningConfig]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <div
        aria-live="assertive"
        className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex-col items-center space-y-4 sm:items-end">
          {toasts.map(({ id, ...notification }) => (
            <Toast
              key={id}
              notification={notification}
              onClose={() =>
                setToasts(toasts.filter((toast) => toast.id !== id))
              }
              show={isToastShowing(id)}
            />
          ))}
        </div>
      </div>
      <ModalWarning
        config={modalWarningConfig}
        setConfig={setModalWarningConfig}
      />
    </ToastContext.Provider>
  );
};

const useToast = (): ContextValue => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within an ToastProvider");
  }
  return context;
};

export { useToast, ToastProvider };

//
// Utils
//

const generateToastId = () =>
  (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);

export interface ModalConfig {
  title?: string;
  message?: string;
  open: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonClassname?: string;
  cancelButtonClassname?: string;
  withoutCancelButton?: boolean;
  resolve?: () => void;
  cancel?: () => void;
}
