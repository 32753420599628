import { Field, FormikHelpers, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useComments } from "../../context/comments";
import { useContacts } from "../../context/contacts";
import MemberAvatarImg from "../../context/members/MemberAvatarImg";
import { useWorkspaces } from "../../context/workspaces";
import classNames from "../../utils/classNames";
import { Comment, CommentType } from "../../utils/types";
import CenteredLoading from "../CenteredLoading";
import FormProvider from "../forms/FormProvider";
import Mentions from "../forms/Mentions";
import CommentComponent from "./comment";

export interface Props {
  type: CommentType;
  typeUuid: string;
}

const CommentsComponent: React.FC<Props> = ({ type, typeUuid }) => {
  const { fetchComments, addComment, comments } = useComments();
  const { member } = useContacts();
  const { currentWorkspace } = useWorkspaces();
  const { hash } = useLocation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentComment, setCurrentComment] = useState<Comment | null>(null);
  const [fetchStatus, setFetchStatus] = useState<
    "idle" | "fetching" | "fetched"
  >("idle");

  const initialValues = useMemo(
    () => ({
      message: currentComment?.message || ""
    }),
    [currentComment]
  );

  useEffect(() => {
    if (!hash) return;

    const commentContainer = document.getElementById(hash);
    const defaultPageContainer = document.getElementById(
      "default-page-container"
    );

    if (!commentContainer || !defaultPageContainer) return;

    defaultPageContainer.scrollTo({
      top: commentContainer.offsetTop,
      behavior: "smooth"
    });
  }, [hash, comments]);

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      setErrorMessage(null);

      try {
        let apiResponse = await addComment(formValues.message, type, typeUuid);

        if (apiResponse.status) {
          formikHelpers.resetForm();
          setCurrentComment(null);
        } else {
          setErrorMessage("Something went wrong");
        }

        formikHelpers.setSubmitting(false);
      } catch (error: any) {
        setErrorMessage(error?.message);
        formikHelpers.setSubmitting(false);
      }
    },
    [addComment, type, typeUuid]
  );

  const fetchCommentsList = useCallback(async () => {
    setFetchStatus("fetching");

    await fetchComments(typeUuid);

    setFetchStatus("fetched");
  }, [fetchComments, typeUuid]);

  useEffect(() => {
    if (fetchStatus === "idle") {
      fetchCommentsList();
    }
  }, [fetchCommentsList, fetchStatus]);

  if (fetchStatus === "fetching") {
    return <CenteredLoading className="mt-6" style={{ height: "134px" }} />;
  }

  return (
    <section>
      <ul className="space-y-6">
        {(comments[typeUuid] ?? []).map((comment: Comment, index) => (
          <CommentComponent
            comment={comment}
            className={classNames(
              "first:mt-6",
              currentComment?.uuid === comment.uuid && "border rounded p-2"
            )}
            key={index}
          />
        ))}
      </ul>

      <div className="flex space-x-3 mt-6">
        <div className="flex-shrink-0 w-10 h-10">
          <MemberAvatarImg
            member={member}
            sizeInPx={40}
            contactUuid={currentWorkspace?.contact_uuid}
          />
        </div>
        <div className="w-full">
          <FormProvider
            error={errorMessage}
            onCancel={() => setCurrentComment(null)}
            submitButtonText={currentComment ? "Update" : "Send Message"}
            submittingSubmitButtonText={currentComment ? "Updating" : "Sending"}
            classnames={{
              wrapper: "sm:max-w-none",
              buttonWrapper: "pr-0 pb-0",
              cancelButton: currentComment ? "block" : "hidden"
            }}
            formikProps={{
              onSubmit,
              initialValues,
              validationSchema: Yup.object().shape({
                message: Yup.string().required("Required")
              })
            }}
            orderedFieldNames={ORDERED_FIELD_NAMES}
          >
            {({ isSubmitting }: FormikProps<FormValues>) => {
              return (
                <Field
                  id="message"
                  name="message"
                  placeholder="Write your message"
                  component={Mentions}
                  withoutErrorLabel
                  disabled={isSubmitting}
                />
              );
            }}
          </FormProvider>
        </div>
      </div>
    </section>
  );
};

export default CommentsComponent;

//
// Utils
//

interface FormValues {
  message: string;
}

const ORDERED_FIELD_NAMES = ["message"];
