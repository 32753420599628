import { Location } from "history";
import moment from "moment";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { AuthenticationState, User } from ".";
import { ApiResponse, fetchApi } from "../../utils/api";
import { GenericFunction } from "../../utils/types";
import { useGoogleAnalytics } from "../googleAnalytics";
import { useToast } from "../toast";
import * as dataMethods from "./data-methods";

interface ContextValue {
  user: User;
  wrapActionWithAuth: <TArgs, TRet>(
    fn: GenericFunction<TArgs, TRet>
  ) => GenericFunction<TArgs, TRet>;
  authenticationState: AuthenticationState;
  startLoginProcess: (
    idProviderName: string,
    returnToUrl?: string
  ) => Promise<void>;
  checkIsUserLoggedIn: (verifyAgain?: boolean) => Promise<void>;
  logout: () => Promise<void>;
  addEmail: (email: string) => Promise<ApiResponse<any>>;
}

const UserContext = React.createContext<ContextValue | undefined>(undefined);

const LOGGED_OUT_USER: User = {
  avatar_url: "",
  email: "",
  locale: "",
  name: "Guest",
  provider_identifier: "",
  provider_nickname: "",
  provider_type: "",
  uuid: "",
  created_at: "",
  updated_at: "",
  affiliate_code_used: null
};

const setReturnToUrl = (returnToUrl: string) => {
  sessionStorage.setItem(`return-to-url`, returnToUrl);
};

export const consumeReturnUrl = (): string | null => {
  const url = sessionStorage.getItem("return-to-url");
  sessionStorage.removeItem("return-to-url");

  return url;
};

export const generateReturnToUrl = (location: Location<unknown>): string => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("returnToUrl", location.pathname);

  return searchParams.toString();
};

const AuthenticationProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<User>({ ...LOGGED_OUT_USER });
  const { trackEvent } = useGoogleAnalytics();
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationState>("checking");
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const trackedSignup = useRef(false);
  const checkingLoginRef = useRef<Promise<User | null>>();

  const checkIsUserLoggedIn = useCallback(
    async (verifyAgain?: boolean) => {
      if (authenticationState === "authenticated" && !verifyAgain) return;

      if (checkingLoginRef.current && !verifyAgain) return;

      checkingLoginRef.current = dataMethods.isUserLoggedIn();
      const user = await checkingLoginRef.current;

      if (user) {
        setUser(user);
        setAuthenticationState("authenticated");
        localStorage.setItem("@host/user", JSON.stringify(user));
      } else {
        setAuthenticationState("unauthenticated");
      }
    },
    [authenticationState]
  );

  useSWR("is-logged-in", checkIsUserLoggedIn, {
    refreshInterval: 60 * 1000,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false
  });

  useEffect(() => {
    if (
      !trackedSignup.current &&
      user?.created_at &&
      moment().utc().diff(moment(user.created_at).utc(), "seconds") < 5
    ) {
      trackedSignup.current = true;

      trackEvent("signup", { method: "Discord" });
    }
  }, [trackEvent, user]);

  const wrapActionWithAuth: ContextValue["wrapActionWithAuth"] = useCallback(
    (fn) => {
      return (...args) => {
        if (authenticationState === "authenticated") return fn(...args);

        addToast({
          type: "info",
          waitForInteraction: true,
          message: (
            <div>
              <p>Please log in to use this feature.</p>
              <button
                type="button"
                className="mt-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={() => {
                  history.push(
                    `/auth/discord?${generateReturnToUrl(location)}`
                  );
                }}
              >
                Login
              </button>
            </div>
          )
        });
      };
    },
    [addToast, authenticationState, history, location]
  );

  const startLoginProcess = useCallback(
    async (idProviderName: string, returnToUrl?: string) => {
      if (authenticationState === "authenticated") return;

      if (returnToUrl) {
        setReturnToUrl(returnToUrl);
      }

      trackEvent("login", {
        method: "Discord"
      });

      try {
        const loginUrl = await dataMethods.getDiscordLoginUrl(
          generateReturnToUrl(location)
        );
        window.location.assign(loginUrl);
      } catch (err) {
        console.error(err);

        history.replace(
          `/auth/${idProviderName.toLocaleLowerCase()}/cb?error=invalid_request`
        );
      }
    },
    [authenticationState, history, location, trackEvent]
  );

  const addEmail = useCallback(
    async (email: string) => {
      try {
        const response = await fetchApi(`/api/personal-data/email`, {
          method: "PUT",
          body: JSON.stringify({ email })
        });

        const apiResponse: ApiResponse<User> = await response.json();

        checkIsUserLoggedIn();

        return apiResponse;
      } catch {
        throw Error("Something went wrong");
      }
    },
    [checkIsUserLoggedIn]
  );

  const logout = useCallback(async () => {
    setAuthenticationState("unauthenticated");
    setUser({ ...LOGGED_OUT_USER });
    localStorage.removeItem("@host/user");

    history.push(`/auth/login`);
  }, [history]);

  const value = useMemo(
    () => ({
      authenticationState,
      checkIsUserLoggedIn,
      logout,
      startLoginProcess,
      user,
      wrapActionWithAuth,
      addEmail
    }),
    [
      authenticationState,
      checkIsUserLoggedIn,
      logout,
      startLoginProcess,
      user,
      wrapActionWithAuth,
      addEmail
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useAuthentication = (): ContextValue => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};

export { useAuthentication, AuthenticationProvider };
