import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import CheckLatestVersion from "./components/CheckLatestVersion";
import AppContext from "./context";
import PrivateRoute from "./layout/PrivateRoute";
import { UpdateEmail } from "./pages/ValidateEmail/UpdateEmail";
import VerifyEmailCode from "./pages/ValidateEmail/VerifyEmailCode";

interface Props {}

const App: React.FC<Props> = () => (
  <Router>
    <AppContext>
      <CheckLatestVersion />
      <Switch>
        <Route exact path="/auth/login" component={UpdateEmail} />
        <Route
          exact
          path="/auth/email/verify-code"
          component={VerifyEmailCode}
        />
        <PrivateRoute />
        <Redirect path="" to="/auth/login" />
      </Switch>
    </AppContext>
  </Router>
);

export default App;
