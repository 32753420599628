import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { findIndex, isEmpty } from "lodash";
import React, { useCallback } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import CloseIcon from "./icons/CloseIcon";

interface Props {
  data: any;
  selectedItemUuid: string;
  path: "installs";
  idKey: string;
  onClose: () => void;
}

const DetailsPagination: React.FC<Props> = ({
  data,
  selectedItemUuid,
  path,
  idKey,
  onClose
}) => {
  const browserLocation = useLocation();
  const browserHistory = useHistory();

  const detailsPageMatch = matchPath<{
    id: string;
    section: string;
  }>(browserLocation.pathname, {
    path: `/${path}/:id/:section`
  });

  const changePage = useCallback(
    (type: "prev" | "next") => {
      if (isEmpty(data)) return;

      let newSelectedItemIndex = 0;
      const currentSelectedItemindex = findIndex(data, {
        [idKey]: selectedItemUuid
      });

      if (type === "prev") {
        newSelectedItemIndex =
          currentSelectedItemindex > 0
            ? currentSelectedItemindex - 1
            : data.length - 1;
      }

      if (type === "next") {
        newSelectedItemIndex =
          currentSelectedItemindex < data.length - 1
            ? currentSelectedItemindex + 1
            : 0;
      }

      browserHistory.push(
        `/${path}/${data[newSelectedItemIndex][idKey]}/${
          detailsPageMatch?.params?.section || "info"
        }${browserHistory.location.search}`
      );
    },
    [
      browserHistory,
      data,
      detailsPageMatch?.params?.section,
      idKey,
      path,
      selectedItemUuid
    ]
  );

  return (
    <nav className="flex" aria-label="Pagination">
      <span className="relative z-0 shadow-sm rounded-md flex justify-between items-center">
        <button
          type="button"
          className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-hotspotty-500 focus:border-hotspotty-500"
          onClick={() => {
            changePage("prev");
          }}
        >
          <span className="sr-only">Previous</span>
          <ChevronUpIcon className="h-5 w-5" />
        </button>

        <button
          type="button"
          className={`-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-hotspotty-500 focus:border-hotspotty-500 ${
            selectedItemUuid ? "" : "rounded-r-md"
          }`}
          onClick={() => changePage("next")}
        >
          <span className="sr-only">Next</span>
          <ChevronDownIcon className="h-5 w-5" />
        </button>

        <button
          onClick={onClose}
          className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white dark:bg-gray-900 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-hotspotty-500 focus:border-hotspotty-500"
        >
          <CloseIcon className="h-5 w-5" />
        </button>
      </span>
    </nav>
  );
};

export default DetailsPagination;
