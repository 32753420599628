import React from "react";
import { RouteComponentProps } from "react-router-dom";
import CommentsComponent from "../../../components/comments";
import { useWorkspaces } from "../../../context/workspaces";

const ContactCommentsTab: React.FC<
  RouteComponentProps<{ id: string }>
> = () => {
  const { currentWorkspace } = useWorkspaces();

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mt-6 pb-5">
        <p className="mt-2 max-w-4xl text-sm text-gray-500 dark:text-gray-300">
          Here you can send messages back and forth to your hotspot
          administrator(s). They will receive an email with your message every
          time you send a message and vice versa.
        </p>
      </div>

      <CommentsComponent
        type="contact"
        typeUuid={currentWorkspace?.contact_uuid || ""}
      />
    </div>
  );
};

export default ContactCommentsTab;
