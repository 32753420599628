import { FieldInputProps, FormikProps } from "formik";
import React, { useMemo } from "react";
import {
  DataFunc,
  Mention,
  MentionsInput,
  SuggestionDataItem
} from "react-mentions";
import classNames from "../../utils/classNames";
import { useForm } from "./FormProvider";

interface Props {
  className?: {
    wrapper: string;
    label: string;
    input: string;
  };
  trigger: string;
  placeholder?: string;
  id?: string;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
  helperText?: string;
  disabled: boolean;
  withoutErrorLabel?: boolean;
  data: SuggestionDataItem[] | DataFunc;
  renderSuggestion: (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => React.ReactNode | undefined;
}

const Mentions: React.FC<Props> = ({
  className,
  id,
  field,
  form: { touched, errors, setFieldTouched, setFieldValue, isValidating },
  label,
  helperText,
  trigger,
  withoutErrorLabel = false,
  placeholder,
  disabled,
  data,
  renderSuggestion
}) => {
  const { isFirstErrorField } = useForm();
  const firstErrorField = isFirstErrorField(errors, field.name);
  const error = useMemo(
    () => (touched[field.name] || firstErrorField) && errors[field.name],
    [errors, field.name, firstErrorField, touched]
  );
  const hasError = useMemo(
    () => !isValidating && !!error,
    [error, isValidating]
  );

  const mentionsProps = useMemo(
    () => ({
      id: id || field.name,
      invalid: hasError ? "true" : null,
      ...field
    }),
    [field, hasError, id]
  );

  const displayTransform = (_id: string, display: string) => {
    return `@${display}`;
  };

  return (
    <div className={classNames(className?.wrapper)}>
      {label && (
        <label
          htmlFor={id || field.name}
          className={classNames(
            "block mb-1 text-sm font-medium text-gray-900 dark:text-white",
            className?.label
          )}
        >
          {label}
        </label>
      )}

      <MentionsInput
        value={field.value}
        placeholder={placeholder}
        onBlur={() => setFieldTouched(field.name, true)}
        disabled={disabled}
        onChange={(_, newValue) => {
          setFieldValue(field.name, newValue, !mentionsProps.invalid);
        }}
        className={classNames(
          "mentionInput h-20",
          className?.input,
          mentionsProps.invalid ? "Invalid" : undefined
        )}
      >
        <Mention
          markup="@[__display__](__id__)"
          trigger={trigger}
          data={data}
          renderSuggestion={renderSuggestion}
          displayTransform={displayTransform}
        />
      </MentionsInput>

      {!withoutErrorLabel && (helperText || hasError) && (
        <p
          className={classNames(
            "field-error mt-2 text-sm text-gray-600",
            hasError && "text-red-600"
          )}
        >
          {hasError ? error : helperText}
        </p>
      )}
    </div>
  );
};

export default Mentions;
