import React from "react";

export const getMessageContentJsx = (content?: string) => {
  let config = [
    {
      regex: /@\[(.*?)\]\((.*?)\)/g,
      fn: (key: any, result: any) => {
        return (
          <span
            key={result[2]}
            className="bg-purple-700 bg-opacity-70 text-white rounded p-0.5"
          >
            @{result[1]}
          </span>
        );
      }
    },
    {
      regex: /\r?\n|\r/g,
      fn: (key: any) => <br key={key} />
    }
  ];

  return processString(config)(content || "");
};

const processString = (options: any) => {
  let key = 0;

  const processInputWithRegex = (option: any, input: any): any => {
    if (!option.fn || typeof option.fn !== "function") {
      return input;
    }

    if (!option.regex || !(option.regex instanceof RegExp)) {
      return input;
    }

    if (typeof input === "string") {
      let regex = option.regex;
      let result = null;
      let output = [];

      while ((result = regex.exec(input)) !== null) {
        let index = result.index;
        let match = result[0];

        output.push(input.substring(0, index));
        output.push(option.fn(++key, result));

        input = input.substring(index + match.length, input.length + 1);
        regex.lastIndex = 0;
      }

      output.push(input);
      return output;
    } else if (Array.isArray(input)) {
      return input.map((chunk) => processInputWithRegex(option, chunk));
    } else {
      return input;
    }
  };

  return function (input: any) {
    if (!options || !Array.isArray(options) || !options.length) {
      return input;
    }

    options.forEach((option) => (input = processInputWithRegex(option, input)));

    return input;
  };
};
