import create from "zustand";
import { persist } from "zustand/middleware";

type WorkspaceState = {
  currentWorkspaceId: string | undefined;
  setCurrentWorkspaceId: (workspaceId: string | undefined) => void;
};

const useWorkspaceState = create<WorkspaceState>(
  persist(
    (set, get) => ({
      currentWorkspaceId: undefined,
      setCurrentWorkspaceId: (workspaceId) =>
        set({ currentWorkspaceId: workspaceId })
    }),
    {
      name: "workspace",
      version: 1
    }
  )
);

export default useWorkspaceState;
