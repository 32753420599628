import geoJSON from "geojson";
import { compact, head, isEmpty, values } from "lodash";
import { Map, MapMouseEvent } from "mapbox-gl";
import React, { useMemo } from "react";
import { GeoJSONLayer, Marker } from "react-mapbox-gl";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useHotspots } from "../../../../context/hotspots";
import { useInstalls } from "../../../../context/installs";

interface Props {
  map: Map;
}

const HotspotLayer: React.FC<Props> = ({ map }) => {
  const { getHotspotCoordinates, hotspotsMap } = useHotspots();
  const history = useHistory();
  const { installs } = useInstalls();
  const { pathname } = useLocation();
  const detailsPageMatch = matchPath<{
    tab: string; // hotspots, locations, hexes, contacts, installs
    id: string;
  }>(pathname, {
    path: ["/:tab/:id/:section"]
  });

  const filteredHotspotsWithLocation = useMemo(
    () =>
      values(hotspotsMap).filter(
        (hotspot) =>
          hotspot.lat &&
          hotspot.lng &&
          values(installs).find(
            (install) => install.hotspot_uuid === hotspot.id
          )
      ),
    [hotspotsMap, installs]
  );

  const memoHotspotsToDisplay = useMemo(
    () =>
      filteredHotspotsWithLocation.map((hotspot) => {
        const coordinates = getHotspotCoordinates(hotspot.id);

        if (!coordinates) return null;

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coordinates.lng, coordinates.lat]
          },
          properties: {
            ...hotspot,
            color: hotspot.active ? "rgb(53, 212, 154)" : "rgb(248, 113, 113)"
          }
        };
      }),
    [getHotspotCoordinates, filteredHotspotsWithLocation]
  );

  const selectedHotspotCoordinates = useMemo(():
    | [number, number]
    | undefined => {
    let coordinates: { h3Index: string; lat: number; lng: number } | undefined;

    if (
      detailsPageMatch?.params.tab !== "installs" &&
      !detailsPageMatch?.params.id
    ) {
      return;
    }

    coordinates = getHotspotCoordinates(
      installs[detailsPageMatch?.params.id]?.hotspot_uuid
    );

    const lat = coordinates?.lat;
    const lng = coordinates?.lng;

    if (!!lng && !!lat) {
      return [lng, lat];
    }

    return;
  }, [
    detailsPageMatch?.params.id,
    detailsPageMatch?.params.tab,
    getHotspotCoordinates,
    installs
  ]);

  const selectedItemMarkerStyle = useMemo((): React.CSSProperties => {
    if (
      detailsPageMatch?.params.tab !== "installs" &&
      !detailsPageMatch?.params.id
    ) {
      return {};
    }

    const currentHotspotUuid =
      installs[detailsPageMatch?.params.id]?.hotspot_uuid;

    if (values(hotspotsMap).length === 0) {
      return {};
    }

    const rgba = hotspotsMap[currentHotspotUuid]?.active
      ? [53, 212, 154]
      : [248, 113, 113];

    return {
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: `rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: `0px 0px 0px 20px rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]},0.15), 0px 0px 0px 10px rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]},0.15)`,
      cursor: "pointer",
      pointerEvents: "none"
    };
  }, [
    detailsPageMatch?.params.id,
    detailsPageMatch?.params.tab,
    hotspotsMap,
    installs
  ]);

  return (
    <>
      {selectedHotspotCoordinates && (
        <Marker
          key={detailsPageMatch?.params?.id}
          style={selectedItemMarkerStyle}
          anchor="center"
          coordinates={selectedHotspotCoordinates}
          className="activeMarker"
        />
      )}

      <GeoJSONLayer
        id="hotspots"
        data={
          isEmpty(filteredHotspotsWithLocation)
            ? emptyGeoJSON
            : {
                type: "FeatureCollection",
                features: compact(memoHotspotsToDisplay)
              }
        }
        circlePaint={hotspotCirclePaint}
        circleOnClick={(e: MapMouseEvent) => {
          const selectedHotspot = head(
            map
              .queryRenderedFeatures(e.point, {
                layers: ["hotspots-circle"]
              })
              .map(mapFeatureToItem)
          );

          if (!selectedHotspot.id) {
            map.getCanvas().style.cursor = "";
            return;
          }

          const currentInstall = values(installs).find(
            (install) => install.hotspot_uuid === selectedHotspot.id
          );

          history.push(`/installs/${currentInstall?.uuid}/info`);

          map.getCanvas().style.cursor = "";
        }}
        circleOnMouseEnter={(e: MapMouseEvent) => {
          map.getCanvas().style.cursor = "pointer";
        }}
        circleOnMouseLeave={(e: MapMouseEvent) => {
          map.getCanvas().style.cursor = "";
        }}
      />
    </>
  );
};

export default HotspotLayer;

//
// Utils
//

const emptyGeoJSON = (geoJSON as any).parse([], {
  Point: ["lat", "lng"]
});

const hotspotCirclePaint = {
  "circle-color": ["get", "color"],
  "circle-stroke-color": "transparent",
  "circle-stroke-width": 5,
  "circle-radius": 5,
  "circle-blur": 0.15
};

const mapFeatureToItem = (f: any) => ({
  lat: f.geometry.coordinates[1],
  lng: f.geometry.coordinates[0],
  ...f.properties
});
