import React from "react"

interface Props {
  className?: string
  style?: React.CSSProperties
}

export const HELIUM_MOBILE_COLOR = "#009FF9"

const HeliumMobileIcon: React.FC<Props> = (props) => (
  <svg
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 41C31.9254 41 41 31.9254 41 20.5C41 9.07457 31.9254 0 20.5 0C9.07457 0 0 9.07457 0 20.5C0 31.9254 9.07457 41 20.5 41ZM12.5 10C12.5 9 11.5 9 10.5 9C9.5 9 9 10 9 11V30C9 31 10 32 11 32C12 32 13 31 13 30V11C13 10 13 10 12.5 10ZM30 10C30 9 29 9 28 9C27 9 27 10 27 11V30C27 31 28 32 29 32C30 32 31 31 31 30V11C31 10 31 10 30 10ZM24.5 14C25.5 14 26 15 26 16V30C26 31 25.5 32 24.5 32C23.5 32 23 31 23 30V16C23 15 23.5 14 24.5 14ZM17 16C17 15 16 15 15 15C14 15 14 16 14 17V30C14 31 15 32 16 32C17 32 18 31 18 30V17C18 16 18 16 17 16ZM20.5 19C21.5 19 22 20 22 21V30C22 31 21.5 32 20.5 32C19.5 32 19 31 19 30V21C19 20 19.5 19 20.5 19Z"
      fill={HELIUM_MOBILE_COLOR}
    />
  </svg>
)

export default HeliumMobileIcon
