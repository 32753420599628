import { toInteger } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { useToast } from "../context/toast";
import usePrevious from "../utils/usePrevious";

const CheckLatestVersion: React.FC<{}> = () => {
  const { addToast } = useToast();

  const { data: version } = useSWR(
    process.env.NODE_ENV === "production" ? "version" : null,
    async () => {
      const response = await fetch(`/version.txt?${Date.now()}`);
      const versionText = await response.text();
      return toInteger(versionText);
    },
    {
      refreshInterval: 5 * 60 * 1000,
      revalidateOnFocus: true,
      revalidateOnReconnect: false,
      refreshWhenHidden: false
    }
  );

  const prevVersion = usePrevious(version);

  useEffect(() => {
    if (version && prevVersion && version > prevVersion) {
      addToast({
        type: "info",
        title: "New version available",
        message: <UpdatingVersionMessage />,
        waitForInteraction: true
      });
    }
  }, [addToast, prevVersion, version]);

  return null;
};

export default CheckLatestVersion;

//
// Utils
//

const UpdatingVersionMessage: React.FC<{}> = () => {
  const [secondsToPageReload, setSecondsToPageReload] = useState(20);

  useEffect(() => {
    const timer = setInterval(
      () => setSecondsToPageReload((prev) => prev - 1),
      1000
    );

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (secondsToPageReload < 1) {
      window.location.reload();
    }
  }, [secondsToPageReload]);

  const message = useMemo(() => {
    let text = "Updating version ";

    if (secondsToPageReload > 1) {
      text += `in ${secondsToPageReload} seconds`;
    } else if (secondsToPageReload === 1) {
      text += `in ${secondsToPageReload} second`;
    } else {
      text += "now!";
    }

    return text;
  }, [secondsToPageReload]);

  return (
    <div>
      <p>{message}</p>
      {secondsToPageReload > 0 && (
        <button
          type="button"
          className="mt-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          onClick={() => {
            window.location.reload();
          }}
        >
          Update now
        </button>
      )}
    </div>
  );
};
