import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useCallback, useRef } from "react";
import { ModalConfig } from "../../context/toast/ToastProvider";

interface Props {
  config: ModalConfig;
  setConfig: React.Dispatch<React.SetStateAction<ModalConfig>>;
}

const ModalWarning: React.FC<Props> = ({ config, setConfig }) => {
  const onClose = useCallback(() => {
    setConfig({ ...config, open: false });
  }, [config, setConfig]);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const onTrigger = useCallback(
    (type: "cancel" | "resolve") => {
      const { cancel, resolve } = config;

      if (type === "cancel" && cancel) {
        cancel();
      }

      if (type === "resolve" && resolve) {
        resolve();
      }

      onClose();
    },
    [config, onClose]
  );

  return (
    <Transition.Root show={config.open} as={Fragment}>
      <Dialog
        as="div"
        open={config.open}
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => onTrigger("cancel")}
        initialFocus={cancelButtonRef}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 dark:bg-gray-800">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  ref={cancelButtonRef}
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:bg-gray-800 dark:hover:text-white"
                  onClick={() => onTrigger("cancel")}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 dark:text-white"
                  >
                    {config.title || "Delete confirmation"}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 dark:text-gray-300">
                      {config.message ||
                        "Are you sure you want to delete this data? This is a permanent action that cannot be undone."}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 flex flex-col-reverse md:flex-row-reverse">
                <button
                  type="button"
                  className={
                    config.confirmButtonClassname ||
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 md:ml-3 sm:w-auto sm:text-sm"
                  }
                  onClick={() => onTrigger("resolve")}
                >
                  {config.confirmButtonText || "Delete"}
                </button>
                {!config.withoutCancelButton && (
                  <button
                    type="button"
                    className={
                      config.cancelButtonClassname ||
                      "bg-white dark:bg-gray-800 py-2 px-4 mb-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 md:mb-0 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-400"
                    }
                    onClick={() => onTrigger("cancel")}
                  >
                    {config.cancelButtonText || "Cancel"}
                  </button>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWarning;
