import { Menu, Transition } from "@headlessui/react";
import { LogoutIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "../context/authentication";
import { useContacts } from "../context/contacts";
import MemberAvatarImg from "../context/members/MemberAvatarImg";
import { useWorkspaces } from "../context/workspaces";
import classNames from "../utils/classNames";
import LoadingIcon from "./icons/LoadingIcon";

interface Props {
  className?: string;
}

const MENU_ITEM_CLASSES =
  "flex items-center block py-2 px-4 text-sm text-gray-700 cursor-pointer";

const WorkspaceButton: React.FC<Props> = ({ className }) => {
  const { currentWorkspace } = useWorkspaces();
  const { member } = useContacts();
  const { user, authenticationState, logout } = useAuthentication();

  if (authenticationState === "checking") {
    return (
      <LoadingIcon className="animate-spin -ml-1 mr-3 h-5 w-5 text-purple-600 dark:text-white" />
    );
  }

  if (authenticationState === "unauthenticated") {
    return (
      <div className={classNames(className, "relative flex-shrink-0")}>
        <Link
          to={`/auth/login`}
          className="bg-gray-50 flex items-center text-sm text-gray-500 hover:text-gray-900 focus:outline-none dark:text-gray-200 dark:hover:text-white dark:bg-transparent"
        >
          <button
            id="add-wallet-button"
            type="button"
            className=" inline-flex items-center justify-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
          >
            Sign with E-mail
          </button>
        </Link>
      </div>
    );
  }

  return (
    <Menu as="div" className={classNames(className, "relative flex-shrink-0")}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-gray-50 rounded-full flex items-center text-sm text-gray-500 hover:text-gray-900 focus:outline-none dark:text-gray-200 dark:hover:text-white dark:bg-transparent">
              <div className="h-8 w-8 sm:h-8 sm:w-8 z-10 rounded-full">
                <MemberAvatarImg
                  member={member}
                  contactUuid={currentWorkspace?.contact_uuid}
                  sizeInPx={40}
                />
              </div>

              <span className="mr-2 sm:mr-0 sm:ml-2 text-md font-medium">
                {user.email}
              </span>

              <div className="flex items-center justify-center h-8 w-8">
                <img
                  width="16"
                  height="16"
                  src="/chevron.png"
                  alt="Hotspotty logo"
                  className="bg-white rounded-full"
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white divide-y divide-gray-100 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:divide-gray-600"
            >
              <div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }: { active: boolean }) => (
                      <div
                        onClick={() => logout()}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white"
                            : "text-gray-700 dark:bg-gray-800 dark:text-white",
                          MENU_ITEM_CLASSES
                        )}
                      >
                        <LogoutIcon className="mr-1.5 h-4 w-4" />
                        <span>Sign out</span>
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default WorkspaceButton;
