import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { FieldInputProps, FormikProps } from "formik";
import React, { useEffect, useMemo, useRef } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import classNames from "../../utils/classNames";
import usePrevious from "../../utils/usePrevious";
import LoadingIcon from "../icons/LoadingIcon";
import { useForm } from "./FormProvider";

export const defaultPhoneCountryCode = "US";

interface Props {
  autocomplete?: string;
  classnames?: {
    wrapper: string;
    label: string;
    input: string;
    inputContainer: string;
  };
  styles?: { input: React.CSSProperties; inputContainer: React.CSSProperties };
  id?: string;
  disabled?: boolean;
  field: FieldInputProps<any>;
  form: FormikProps<any>;
  label?: string;
  prependText?: string;
  helperText?: string;
  textarea?: boolean;
  oneColumn?: boolean;
  withoutErrorLabel?: boolean;
  enableScroll?: boolean;
  transform?: (newValue: any) => any;
  isPhone?: boolean;
}

const Input: React.FC<Props> = ({
  classnames,
  autocomplete,
  id,
  field,
  form: {
    touched,
    errors,
    submitCount,
    setFieldValue,
    setFieldTouched,
    isValidating
  },
  label,
  prependText,
  helperText,
  textarea = false,
  oneColumn = false,
  withoutErrorLabel = false,
  enableScroll = true,
  styles,
  transform,
  isPhone,
  children,
  ...otherProps
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { isFirstErrorField } = useForm();
  const prevSubmitCount = usePrevious(submitCount);

  const firstErrorField = isFirstErrorField(errors, field.name);
  const error = useMemo(
    () => (touched[field.name] || firstErrorField) && errors[field.name],
    [errors, field.name, firstErrorField, touched]
  );
  const hasError = useMemo(
    () => !isValidating && !!error,
    [error, isValidating]
  );

  const scrollToElement = () => {
    if (containerRef.current?.scrollIntoView) {
      setTimeout(() => {
        containerRef.current?.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  useEffect(() => {
    if (submitCount > prevSubmitCount && firstErrorField && enableScroll) {
      scrollToElement();
    }
  }, [submitCount, prevSubmitCount, firstErrorField, enableScroll]);

  useEffect(() => {
    if (transform != null) {
      const newValue = transform(field.value);

      if (newValue !== field.value) {
        setFieldValue(field.name, newValue);

        if (newValue) {
          setFieldTouched(field.name, true);
        }
      }
    }
  }, [field.name, field.value, setFieldTouched, setFieldValue, transform]);

  const inputProps = useMemo(
    () => ({
      id: id || Math.random().toString(),
      invalid: hasError ? "true" : null,
      ...field,
      ...otherProps
    }),
    [field, hasError, id, otherProps]
  );

  const phoneCountry = useMemo(() => {
    if (isPhone && typeof field.value === "string" && field.value.length) {
      let parsedPhone = parsePhoneNumber(field.value);

      return parsedPhone?.countryCallingCode || defaultPhoneCountryCode;
    }

    return defaultPhoneCountryCode;
  }, [field.value, isPhone]);

  return (
    <div className={classNames(classnames?.wrapper)} ref={containerRef}>
      {label && (
        <label
          htmlFor={inputProps.id}
          className={classNames(
            "block text-sm font-medium text-gray-900 dark:text-white",
            classnames?.label
          )}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          "mt-1 relative",
          (inputProps.disabled === undefined ? false : inputProps.disabled) &&
            "pointer-events-none opacity-50"
        )}
      >
        <div
          className={classNames(
            "relative rounded-md sm:max-w-lg flex",
            classnames?.inputContainer || ""
          )}
          style={styles?.inputContainer || {}}
        >
          {prependText && (
            <span className="inline-flex items-center p-3 border-b border-gray-100 text-gray-500 sm:text-sm">
              {prependText}
            </span>
          )}

          {textarea ? (
            <textarea
              className={classNames(
                "block w-full shadow-sm sm:text-sm rounded-md transition duration-150 ease-in-out dark:bg-gray-800 dark:text-white",
                inputProps.invalid
                  ? "focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "appearance-none border-gray-300 placeholder-gray-400 focus:shadow-outline-purple focus:border-purple-300",
                classnames?.input
              )}
              {...inputProps}
            />
          ) : isPhone ? (
            <PhoneInput
              {...inputProps}
              className={classNames(
                "w-full",
                classnames?.input,
                inputProps.invalid ? "InvalidPhone" : undefined
              )}
              addInternationalOption={false}
              country={phoneCountry}
              onChange={(e: any) => {
                setFieldValue(field.name, e, !inputProps.invalid);
              }}
            />
          ) : (
            <input
              {...inputProps}
              className={classNames(
                "block w-full shadow-sm sm:text-sm rounded-md transition duration-150 ease-in-out pr-10 dark:bg-gray-800 dark:text-white",
                inputProps.invalid
                  ? "focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                  : "appearance-none border-gray-300 placeholder-gray-400 focus:border-purple-300 focus:shadow-outline-purple",
                !!prependText && "flex-1 min-w-0 rounded-none rounded-r-md",
                classnames?.input
              )}
              autoComplete={autocomplete || ""}
              style={styles?.input || {}}
            />
          )}

          {children || null}

          {!isValidating && hasError && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
              />
            </div>
          )}

          {isValidating && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <LoadingIcon className="h-5 w-5 animate-spin" />
            </div>
          )}
        </div>

        {!withoutErrorLabel && (helperText || hasError) && (
          <p
            className={classNames(
              "field-error mt-2 text-sm text-gray-600",
              hasError && "text-red-600"
            )}
          >
            {hasError ? error : helperText}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
