import animalHash from "angry-purple-tiger";
import { stringifyUrl } from "query-string";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import StatusDot from "../../components/StatusDot";
import { getLabelColorStyle } from "../../components/labels/utils";
import { avatarsApi } from "../../utils/constants";
import { formatDateToLocale } from "../../utils/dates";
import {
  CommissionReport,
  CommissionSubset,
  CommissionType,
  ContactCommission,
  InstallCommission
} from "../../utils/types";
import useCurrency from "../../utils/useCurrency";
import useNetwork from "../../utils/useNetwork";
import { useAppearance } from "../appearance";
import { useHotspots } from "../hotspots";
import { useTokens } from "../tokens";

interface Props {
  commissionReport: CommissionReport;
  commissions: ContactCommission[];
  closeSidebar: (isCloseAll: boolean) => void;
}

const ContactCommissionsList: React.FC<Props> = ({
  commissionReport,
  commissions,
  closeSidebar
}) => {
  const { hotspotsMap } = useHotspots();
  const { isDark } = useAppearance();
  const { currencyDisplay } = useCurrency();
  const { displayInCurrency } = useTokens();
  const { networkItem } = useNetwork();
  const { getCurrencyTitle } = useCurrency();

  const getCommissionValueLabel = useCallback(
    ({
      currency,
      type,
      value
    }: Pick<InstallCommission, "currency" | "type" | "value">) => {
      let labelText = value.toString();

      if (type === CommissionType.percentage) {
        labelText += "%";
      }

      labelText += " " + getCurrencyTitle(currency);

      return labelText;
    },
    [getCurrencyTitle]
  );

  return (
    <>
      {commissions.length === 1 ? (
        <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
          <span>Commission</span>
          <span>Amount</span>
        </h3>
      ) : (
        <h3 className="text-sm font-medium text-gray-500 flex items-center justify-between">
          <span>Commissions ({commissions.length})</span>
          <span>Amounts</span>
        </h3>
      )}
      <dl className="mt-2 border-t border-gray-200 divide-y divide-gray-200">
        {commissions.map((contactCommission) => {
          const { totalCommissionValue, commission, install } =
            contactCommission;
          const hotspot = hotspotsMap[install.hotspot.uuid];

          return (
            <div key={commission.uuid}>
              <div className="py-3 flex items-start justify-between text-sm font-medium">
                <dt className="flex-1">
                  <Link
                    className="relative flex items-center space-x-3 group text-sm font-medium text-gray-900 dark:text-white"
                    to={`/installs/${install.uuid}/info`}
                    onClick={() => {
                      closeSidebar(true);
                    }}
                  >
                    <div className="flex-shrink-0">
                      <span className="inline-block relative">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={stringifyUrl({
                            url: `${avatarsApi}/bottts/${install.hotspot.uuid}.svg`,
                            query: { w: 40, h: 40 }
                          })}
                          alt="Hotspot avatar"
                        />
                        {hotspot && (
                          <StatusDot
                            hotspot={hotspot}
                            className="absolute top-0 right-0 block"
                          />
                        )}
                      </span>
                    </div>
                    <div className="flex-1 min-w-0 focus:outline-none">
                      <div>
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                          {animalHash(install.hotspot.uuid)}
                        </p>

                        {install.installed_at && (
                          <p className="text-xs font-light text-gray-500 truncate dark:text-gray-400">
                            Hotspot installed{" "}
                            {install.uninstalled_at
                              ? `from ${formatDateToLocale(
                                  install.installed_at
                                )} to ${formatDateToLocale(
                                  install.uninstalled_at
                                )}`
                              : `on ${formatDateToLocale(
                                  install.installed_at
                                )}`}
                          </p>
                        )}
                        <p className="text-xs font-light text-gray-500 truncate dark:text-gray-400">
                          {getCommissionPeriodText(commission)}
                        </p>
                      </div>
                      <div className="flex items-center flex-wrap">
                        <div
                          className="inline-flex items-center rounded-full font-medium px-3 text-sm mt-2"
                          style={getLabelColorStyle("#d73aaa", isDark)}
                        >
                          {getCommissionValueLabel(commission)}
                        </div>
                      </div>
                    </div>
                  </Link>
                </dt>
                <dd className="text-md font-semibold text-gray-900 dark:text-white">
                  {networkItem.tokens.includes(commissionReport.currency)
                    ? displayInCurrency(totalCommissionValue, {
                        withTokenSuffix: true,
                        fractionDigits: 3,
                        displayCurrency: commissionReport.currency
                      })
                    : currencyDisplay(
                        commissionReport.currency,
                        totalCommissionValue
                      )}
                </dd>
              </div>
            </div>
          );
        })}
      </dl>
    </>
  );
};

export default ContactCommissionsList;

//
// Utils
//

const getCommissionPeriodText = (commission: CommissionSubset) => {
  if (commission.type === CommissionType.oneTimeFixed) {
    return `Commission on ${formatDateToLocale(commission.start_date)}`;
  }

  const text = `Commissions from ${formatDateToLocale(commission.start_date)}`;

  return commission.end_date
    ? `${text} to ${formatDateToLocale(commission.end_date)}`
    : text;
};
