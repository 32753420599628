import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { Form, Formik, FormikConfig, FormikErrors } from "formik";
import { reduce } from "lodash";
import React, { useCallback, useContext } from "react";
import classNames from "../../utils/classNames";
import LoadingIcon from "../icons/LoadingIcon";
import ScrollToTop from "../ScrollToTop";

interface Props {
  classnames?: {
    wrapper?: string;
    form?: string;
    buttonWrapper?: string;
    cancelButton?: string;
  };
  error?: string | null;
  formikProps: FormikConfig<any>; // TODO: Use TS generic to pass Values type
  disabled?: boolean;
  onCancel?: () => void;
  submitButtonText?: string;
  submittingSubmitButtonText?: string;
  cancelButtonText?: string;
  orderedFieldNames: string[];
  withoutSubmitButton?: boolean;
}

type ContextValue = {
  isFirstErrorField: (errors: FormikErrors<any>, field: string) => boolean;
};

const FormContext = React.createContext<ContextValue | undefined>(undefined);

const FormProvider: React.FC<Props> = ({
  classnames,
  error,
  formikProps,
  disabled = false,
  children,
  onCancel,
  submitButtonText,
  submittingSubmitButtonText,
  cancelButtonText,
  orderedFieldNames,
  withoutSubmitButton = false
}) => {
  const isFirstErrorField = useCallback(
    (errors: FormikErrors<any>, field: string) => {
      const firstErrorField = reduce(
        orderedFieldNames,
        (res: null | string, fieldName) =>
          res === null && errors[fieldName] ? fieldName : res,
        null
      );
      return firstErrorField === field;
    },
    [orderedFieldNames]
  );

  return (
    <FormContext.Provider value={{ isFirstErrorField }}>
      <div
        className={classNames("max-w-3xl xl:max-w-6xl", classnames?.wrapper)}
      >
        <ScrollToTop />
        <Formik enableReinitialize {...formikProps}>
          {(formProps) => (
            <Form
              className={
                classnames?.form ||
                "h-full flex flex-col bg-white dark:bg-gray-900"
              }
            >
              {children instanceof Function ? children(formProps) : children}

              {!formProps.isSubmitting && error && (
                <div className="rounded-md bg-red-50 sm:p-4 sm:mx-4 mt-4 dark:bg-transparent">
                  <ScrollToTop behavior="smooth" />
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm leading-5 font-medium text-red-800">
                        {error}
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              {!withoutSubmitButton && (
                <div
                  className={classNames(
                    "flex-shrink-0 px-4 py-4 flex justify-end",
                    classnames?.buttonWrapper
                  )}
                >
                  {onCancel && (
                    <button
                      type="button"
                      className={classNames(
                        "bg-white dark:bg-gray-800 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-400",
                        classnames?.cancelButton
                      )}
                      onClick={onCancel}
                    >
                      {cancelButtonText || "Cancel"}
                    </button>
                  )}
                  <button
                    type="submit"
                    className="ml-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 flex items-center disabled:opacity-50 disabled:cursor-default disabled:hover:bg-purple-600"
                    disabled={formProps.isSubmitting || disabled}
                  >
                    {formProps.isSubmitting && (
                      <LoadingIcon className="animate-spin -ml-1 mr-3 h-5 w-5" />
                    )}
                    <span>
                      {formProps.isSubmitting
                        ? submittingSubmitButtonText || "Saving"
                        : submitButtonText || "Save"}
                    </span>
                  </button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </FormContext.Provider>
  );
};

export default FormProvider;

export const useForm = (): ContextValue => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return context;
};
