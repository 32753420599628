import { Field, FormikHelpers, FormikProps } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import BrandedHeader from "../../components/BrandedHeader";
import FormProvider from "../../components/forms/FormProvider";
import Input from "../../components/forms/Input";
import { useAuthentication } from "../../context/authentication";
import { ApiResponse, fetchApi } from "../../utils/api";
import { MessageBox } from "./UpdateEmail";

const VerifyEmailCode: React.FC<RouteComponentProps> = (props) => {
  const { checkIsUserLoggedIn } = useAuthentication();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const queryString = useMemo(
    () => new URLSearchParams(props.location.search),
    [props.location.search]
  );

  const email = useMemo(() => localStorage.getItem("@host/email"), []);

  useEffect(() => {
    if (!email || email === "") {
      props.history.push("/auth/login");
    }
  }, [props.history, email]);

  const code = useMemo(
    () => (queryString.has("code") ? queryString.get("code") : ""),
    [queryString]
  );

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      setErrorMessage(null);

      const response = await fetchApi(`/api/verification-code/verify`, {
        method: "POST",
        body: JSON.stringify({ code: formValues.code, email })
      });

      const apiResponse: ApiResponse<[]> = await response.json();

      if (apiResponse.status) {
        await checkIsUserLoggedIn(true);

        formikHelpers.setSubmitting(false);
        props.history.push("/workspace");
        return;
      }

      formikHelpers.setSubmitting(false);

      setErrorMessage("Something went wrong");
    },
    [checkIsUserLoggedIn, email, props]
  );

  return (
    <>
      <BrandedHeader
        logo="/favicon-32x32.png"
        title="Hotspotty Host Dashboard"
        website="https://hotspotty.net"
      />
      <div
        className="flex flex-col items-center justify-center dark:bg-gray-900 dark:text-white"
        style={{
          minHeight: "calc(100vh - 49px)",
          maxHeight: "calc(100vh - 49px)"
        }}
      >
        <MessageBox
          title={
            <span className="font-normal text-purple-400 dark:text-white">
              Please enter the verification code that we sent to <b>{email}</b>{" "}
              to continue.
            </span>
          }
          message={
            <div className="w-full mt-4">
              <FormProvider
                error={errorMessage}
                submitButtonText="Continue"
                submittingSubmitButtonText="Sending..."
                cancelButtonText="Change email"
                onCancel={() => props.history.push("/auth/login")}
                classnames={{
                  form: "bg-gray-50 dark:bg-gray-800 p-4 sm:p-0 rounded-md",
                  buttonWrapper: "pb-0 pr-0"
                }}
                formikProps={{
                  onSubmit,
                  initialValues: { code },
                  validationSchema: Yup.object().shape({
                    code: Yup.string().min(6).max(6).required("Required")
                  })
                }}
                orderedFieldNames={ORDERED_FIELD_NAMES}
              >
                {({ isSubmitting }: FormikProps<FormValues>) => {
                  return (
                    <Field
                      id="search_field"
                      name="code"
                      label="Code"
                      placeholder="Insert your code"
                      type="text"
                      classnames={{ wrapper: "w-full" }}
                      component={Input}
                      disabled={isSubmitting}
                    />
                  );
                }}
              </FormProvider>
            </div>
          }
        />
      </div>
    </>
  );
};

export default VerifyEmailCode;

//
// Utils
//

interface FormValues {
  code: string;
}

const ORDERED_FIELD_NAMES = ["code"];
