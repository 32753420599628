import { ContactPaymentContextKey } from "./types";

const getPaymentContextTitle = (context: ContactPaymentContextKey) => {
  switch (context) {
    case ContactPaymentContextKey.manual:
      return "Manual";
    case ContactPaymentContextKey.csv:
      return "CSV";
    case ContactPaymentContextKey.qr:
      return "QR";
    case ContactPaymentContextKey.cli:
      return "CLI";
    case ContactPaymentContextKey.wiseByEmail:
      return "Wise.com csv";
    case ContactPaymentContextKey.paypal:
      return "PayPal csv";
    default:
      return context;
  }
};

export default getPaymentContextTitle;
