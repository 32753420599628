import {
  geoToH3,
  h3Distance,
  h3GetResolution,
  H3IndexInput,
  h3SetToMultiPolygon,
  h3ToCenterChild,
  h3ToChildren,
  h3ToGeo,
  h3ToParent,
  kRing,
  pointDist
} from "h3-js";
import memoize from "memoizee";
import { HotspotLocation } from "./types";
import { Coordinates } from "./useMapCenter";

const h3DistanceMap = new Map<string, number>();

export const h3DistanceMemoized = (
  origin: H3IndexInput,
  destination: H3IndexInput
) => {
  if (typeof origin !== "string" || typeof destination !== "string")
    return h3Distance(origin, destination);

  const key = `${origin}${destination}`;
  const reverseKey = `${destination}${origin}`;

  if (h3DistanceMap.has(key) || h3DistanceMap.has(reverseKey)) {
    return h3DistanceMap.get(key) as number;
  }

  const distance = h3Distance(origin, destination);

  h3DistanceMap.set(key, distance);
  h3DistanceMap.set(reverseKey, distance);

  return distance;
};

const pointDistanceMap = new Map<string, number>();

export const pointDistanceMemoized = (
  origin: Coordinates,
  destination: Coordinates
) => {
  const key = `${origin.join(",")}-${destination.join(",")}`;
  const reverseKey = `${destination.join(",")}-${origin.join(",")}`;

  if (pointDistanceMap.has(key) || pointDistanceMap.has(reverseKey)) {
    return pointDistanceMap.get(key) as number;
  }

  const distance = Math.round(
    pointDist([origin[1], origin[0]], [destination[1], destination[0]], "m")
  );

  pointDistanceMap.set(key, distance);
  pointDistanceMap.set(reverseKey, distance);

  return distance;
};

const isActiveTransmitScaleMap = new Map<string, boolean>();

export const isActiveTransmitScaleMemoized = (
  uuid: string,
  value?: boolean
) => {
  if (value !== undefined) {
    isActiveTransmitScaleMap.set(uuid, value);

    return value;
  }

  if (isActiveTransmitScaleMap.has(uuid)) {
    return isActiveTransmitScaleMap.get(uuid);
  }
};

export const h3GetResolutionMemoized = memoize(h3GetResolution);

export const h3ToGeoMemoized = memoize(h3ToGeo);

export const h3ToCenterChildMemoized = memoize(h3ToCenterChild);

export const geoToH3Memoized = memoize(geoToH3);

export const h3ToParentMemoized = memoize(h3ToParent, { primitive: true });

export const kRingMemoized = memoize(kRing);

export const h3ToChildrenMemoized = memoize(h3ToChildren);

export const h3SetToMultiPolygonMemoized = memoize(h3SetToMultiPolygon);

export const getLocationsInHex = (
  locations: HotspotLocation[],
  h3Index: string
) =>
  locations.filter(
    ({ h3_index_r12 }) =>
      h3ToParentMemoized(h3_index_r12, h3GetResolutionMemoized(h3Index)) ===
      h3Index
  );
