import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { find, isEmpty } from "lodash";
import { Fragment, useMemo } from "react";
import { getLabelColorStyle } from "../../../components/labels/utils";
import { useAppearance } from "../../../context/appearance";
import { useCommissionReports } from "../../../context/commissionReports";
import ContactCommissionsList from "../../../context/commissionReports/ContactCommissionsList";
import { useTokens } from "../../../context/tokens";
import { formatDateToLocale } from "../../../utils/dates";
import getPaymentContextTitle from "../../../utils/getPaymentContextTitle";
import {
  CommissionReport,
  ContactCommission,
  ContactPayment
} from "../../../utils/types";
import useCurrency from "../../../utils/useCurrency";
import useNetwork from "../../../utils/useNetwork";

interface Props {
  contactPayment: ContactPayment | null;
  open: boolean;
  onClose: () => void;
}

const ContactPaymentSidebar: React.FC<Props> = ({
  contactPayment,
  open,
  onClose
}) => {
  const { displayInCurrency } = useTokens();
  const { getCurrencyTitle, currencyDisplay } = useCurrency();
  const { commissionReports } = useCommissionReports();
  const { isDark } = useAppearance();
  const { networkItem } = useNetwork();

  const renderSubtitle = useMemo(() => {
    if (!contactPayment) return null;

    return (
      <div className="flex items-center flex-wrap">
        <div
          className="inline-flex items-center rounded-full font-medium px-3 text-sm mt-2"
          style={getLabelColorStyle("#d73aaa", isDark)}
        >
          {getCurrencyTitle(contactPayment.currency)}
        </div>
      </div>
    );
  }, [contactPayment, getCurrencyTitle, isDark]);

  const [commissionReport, commissions]: [
    CommissionReport | undefined,
    ContactCommission[]
  ] = useMemo(() => {
    const commissionReport = contactPayment?.report_commission_uuid
      ? commissionReports[contactPayment.report_commission_uuid]
      : undefined;

    const commissions =
      commissionReport && contactPayment
        ? find(
            commissionReport.report,
            ({ contact }) => contact.uuid === contactPayment.contact_uuid
          )?.commissions
        : undefined;

    return [commissionReport, commissions || []];
  }, [commissionReports, contactPayment]);

  const renderContent = useMemo(() => {
    if (!contactPayment) return null;

    return (
      <div className="flex-1 py-6 divide-y divide-gray-200 dark:divide-gray-500 overflow-y-auto">
        <div className="px-4 sm:px-6 space-y-6">
          <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                Paid amount
              </dt>

              <dd className="flex items-baseline mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                {networkItem.tokens.includes(contactPayment.currency)
                  ? displayInCurrency(contactPayment.amount, {
                      withTokenSuffix: true,
                      fractionDigits: 3,
                      displayCurrency: contactPayment.currency
                    })
                  : currencyDisplay(
                      contactPayment.currency,
                      contactPayment.amount
                    )}
              </dd>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Payment date</h3>
            <div className="mt-2">
              {contactPayment.paid_at
                ? formatDateToLocale(contactPayment.paid_at)
                : "-"}
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Context</h3>
            <div className="mt-2">
              {contactPayment.context
                ? getPaymentContextTitle(contactPayment.context)
                : "-"}
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Notes</h3>
            <div className="mt-2">
              {contactPayment.notes ? (
                <pre className="text-sm">{contactPayment.notes}</pre>
              ) : (
                "-"
              )}
            </div>
          </div>

          {commissionReport && !isEmpty(commissions) && (
            <div>
              <ContactCommissionsList
                commissionReport={commissionReport}
                commissions={commissions}
                closeSidebar={onClose}
              />
            </div>
          )}
        </div>
      </div>
    );
  }, [
    commissionReport,
    commissions,
    contactPayment,
    currencyDisplay,
    displayInCurrency,
    networkItem.tokens,
    onClose
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-20 overflow-hidden"
        open={open}
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <div className="absolute inset-0" onClick={onClose} />
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md dark:text-white">
                <div className="max-w-3xl xl:max-w-6xl h-full">
                  <div className="h-full flex flex-col bg-white shadow-xl dark:bg-gray-900">
                    <div className="flex-1 h-0 flex flex-col">
                      <div className="flex-none py-6 px-4 bg-hotspotty-700 sm:px-6 dark:bg-gray-800">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Contact payment
                            {renderSubtitle}
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hotspotty-400 dark:bg-gray-800 dark:hover:text-white"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      {renderContent}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ContactPaymentSidebar;
