import { isEqual } from "lodash";
import create from "zustand";
import { geoToH3Memoized } from "./memoizedMethods";

export type Coordinates = [number, number]; // [lng, lat]

export const isCoordinates = (arg: unknown): arg is Coordinates => {
  if (!Array.isArray(arg)) return false;

  const [lng, lat] = arg;

  return typeof lng === "number" && typeof lat === "number";
};

export interface Boundaries {
  sw: Coordinates;
  ne: Coordinates;
}

type MapCenterState = {
  boundaries?: Boundaries;
  setBoundaries: (newBoundaries: { sw: Coordinates; ne: Coordinates }) => void;
  center: Coordinates;
  centerInH3HexRes4: string | undefined;
  setCenter: (newCenter: Coordinates) => void;
  fetchedDataCenter: string | undefined; // centerInH3HexRes4 of the fetched data which defines the center of the purple zone on the map
  setFetchedDataCenter: (newCenter: string | undefined) => void;
  zoom: [number];
  setZoom: (newZoom: [number]) => void;
};

const useMapCenter = create<MapCenterState>((set, get) => ({
  center: [0, 0], // TODO: add current location
  centerInH3HexRes4: undefined,
  setBoundaries: (newBoundaries) => {
    const { boundaries: oldBoundaries } = get();

    if (isEqual(oldBoundaries, newBoundaries)) return;

    set({
      boundaries: newBoundaries
    });
  },
  setCenter: (newCenter) => {
    const { center: oldCenter, centerInH3HexRes4: oldCenterInH3HexRes4 } =
      get();

    if (isEqual(oldCenter, newCenter)) return;

    const newCenterInH3HexRes4 = geoToH3Memoized(newCenter[1], newCenter[0], 4);

    if (isEqual(oldCenterInH3HexRes4, newCenterInH3HexRes4)) {
      set({ center: newCenter });
    } else {
      set({ center: newCenter, centerInH3HexRes4: newCenterInH3HexRes4 });
    }
  },
  fetchedDataCenter: undefined,
  setFetchedDataCenter: (newCenter) => set({ fetchedDataCenter: newCenter }),
  zoom: [11],
  setZoom: (newZoom) => set({ zoom: newZoom })
}));

/**
 * Checks if a point is inside (exclusive) the boundaries.
 */
export const isPointInsideBoundaries = (
  point: Coordinates,
  boundaries: Boundaries
): boolean => {
  //Checks longitude
  if (point[0] <= boundaries.sw[0] || point[0] >= boundaries.ne[0]) {
    return false;
  }

  //Checks latitude
  if (point[1] <= boundaries.sw[1] || point[1] >= boundaries.ne[1]) {
    return false;
  }

  return true;
};

export default useMapCenter;
