import { Dialog, Switch, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import React, { Fragment, useMemo } from "react";
import { ThemeSelector } from "../../context/appearance";
import { useGoogleAnalytics } from "../../context/googleAnalytics";
import classNames from "../../utils/classNames";
import useMapConfig, { VisualizationLayer } from "../../utils/useMapConfig";

interface Props {
  open: boolean;
  onClose: () => void;
}

const MapAppearanceSettingsSidebar: React.FC<Props> = ({ open, onClose }) => {
  const visibleLayers = useMapConfig((state) => state.visibleLayers);
  const { trackEvent } = useGoogleAnalytics();
  const toggleVisibleLayer = useMapConfig((state) => state.toggleVisibleLayer);

  const layers = useMemo(() => {
    const allLayers = [
      {
        key: VisualizationLayer.satellite,
        hidden: false,
        title: "Satellite view",
        subtitle: "Add a satellite view overlay to the map"
      }
    ];

    return allLayers.filter(({ hidden }) => !hidden);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-20 overflow-hidden"
        open={open}
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <div className="absolute inset-0" onClick={onClose} />
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-md dark:text-white">
                <div className="max-w-3xl xl:max-w-6xl h-full">
                  <div className="h-full flex flex-col bg-white shadow-xl dark:bg-gray-900">
                    <div className="flex-1 h-0 overflow-y-auto">
                      <div className="py-6 px-4 bg-purple-700 sm:px-6 dark:bg-gray-800">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Appearance settings
                          </Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 dark:bg-gray-800 dark:hover:text-white"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="flex-1 pt-6 divide-y divide-gray-200 dark:divide-gray-500 overflow-y-auto">
                        <div className="px-4 sm:px-6">
                          <div>
                            <div className="grid">
                              <div>
                                <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                  Theme
                                </h3>
                                <ThemeSelector className="mt-2" />
                              </div>
                            </div>

                            <h2 className="mt-6 text-lg leading-6 font-medium text-gray-900 dark:text-white">
                              Layers
                            </h2>
                            <p className="mt-1 text-sm text-gray-500 dark:text-gray-200">
                              Show/hide layers on the map. Note that some layers
                              will always be displayed depending on the context.
                            </p>

                            <ul className="mt-2 divide-y divide-gray-200 dark:divide-gray-500">
                              {layers.map(({ key, title, subtitle }) => (
                                <Switch.Group
                                  as="li"
                                  className="py-4 flex items-center justify-between"
                                  key={key}
                                >
                                  <div className="flex flex-col">
                                    <Switch.Label
                                      as="div"
                                      className="text-sm font-medium mb-1 text-gray-900 dark:text-white"
                                      passive
                                    >
                                      {title}
                                    </Switch.Label>
                                    <Switch.Description className="text-sm text-gray-500 dark:text-gray-200">
                                      {subtitle}
                                    </Switch.Description>
                                  </div>
                                  <Switch
                                    checked={visibleLayers[key]}
                                    onChange={() => {
                                      trackEvent("toggle visualization layer", {
                                        event_category: "user settings",
                                        event_label: key,
                                        value: !visibleLayers[key] ? 1 : 0
                                      });
                                      toggleVisibleLayer(key);
                                    }}
                                    className={classNames(
                                      visibleLayers[key]
                                        ? "bg-purple-600 dark:bg-gray-100 dark:hover:bg-gray-200"
                                        : "bg-gray-200 dark:bg-gray-500 dark:hover:bg-gray-400",
                                      "ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-purple-500"
                                    )}
                                  >
                                    <span className="sr-only">{title}</span>
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        visibleLayers[key]
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MapAppearanceSettingsSidebar;
