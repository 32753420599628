import { map, toUpper } from "lodash";
import create from "zustand";

export enum CurrencyKey {
  usd = "usd",
  eur = "eur",
  gbp = "gbp",
  cad = "cad",
  aud = "aud",
  brl = "brl",
  jpy = "jpy",
  dkk = "dkk",
  sek = "sek",
  chf = "chf",
  inr = "inr",
  cny = "cny",
  myr = "myr",
  sgd = "sgd",
  nzd = "nzd",
  // Tokens
  tokens = "tokens",
  hnt = "hnt",
  mobile = "mobile",
  iot = "iot"
}

export interface CurrencyOption {
  title: string;
  wiseCurrency?: string;
  paypalCurrency?: string;
}

// Paypal: https://developer.paypal.com/reference/currency-codes/
export const currencies: { [key in keyof typeof CurrencyKey]: CurrencyOption } =
  {
    // Tokens
    [CurrencyKey.tokens]: { title: "HNT, MOBILE, IOT" },
    [CurrencyKey.hnt]: { title: "HNT" },
    [CurrencyKey.mobile]: { title: "MOBILE" },
    [CurrencyKey.iot]: { title: "IOT" },
    // Currencies
    [CurrencyKey.usd]: {
      title: "USD",
      wiseCurrency: "USD",
      paypalCurrency: "USD"
    },
    [CurrencyKey.eur]: {
      title: "EURO",
      wiseCurrency: "EUR",
      paypalCurrency: "EUR"
    },
    [CurrencyKey.gbp]: {
      title: "GBP",
      wiseCurrency: "GBP",
      paypalCurrency: "GBP"
    },
    [CurrencyKey.cad]: {
      title: "CAD",
      wiseCurrency: "CAD",
      paypalCurrency: "CAD"
    },
    [CurrencyKey.aud]: {
      title: "AUD",
      wiseCurrency: "AUD",
      paypalCurrency: "AUD"
    },
    [CurrencyKey.brl]: {
      title: "BRL",
      wiseCurrency: "BRL",
      paypalCurrency: "BRL"
    },
    [CurrencyKey.jpy]: {
      title: "JPY",
      wiseCurrency: "JPY"
    },
    [CurrencyKey.dkk]: {
      title: "DKK",
      wiseCurrency: "DKK",
      paypalCurrency: "DKK"
    },
    [CurrencyKey.sek]: {
      title: "SEK",
      wiseCurrency: "SEK",
      paypalCurrency: "SEK"
    },
    [CurrencyKey.chf]: {
      title: "CHF",
      wiseCurrency: "CHF",
      paypalCurrency: "CHF"
    },
    [CurrencyKey.inr]: { title: "INR", wiseCurrency: "INR" },
    [CurrencyKey.cny]: { title: "CNY", paypalCurrency: "CNY" },
    [CurrencyKey.myr]: {
      title: "MYR",
      wiseCurrency: "MYR",
      paypalCurrency: "MYR"
    },
    [CurrencyKey.sgd]: {
      title: "SGD",
      wiseCurrency: "SGD",
      paypalCurrency: "SGD"
    },
    [CurrencyKey.nzd]: {
      title: "NZD",
      wiseCurrency: "NZD",
      paypalCurrency: "NZD"
    }
  };

type CurrencyState = {
  currency: CurrencyKey;
  currencyOptions: Array<{ key: string; title: string }>;
  setCurrency: (newCurrency: CurrencyKey) => void;
  getCurrencyTitle: (currencyKey: CurrencyKey) => string;
  getCurrencyOption: (currencyKey: CurrencyKey) => CurrencyOption;
  currencyDisplay: (
    currency: CurrencyKey,
    amount: number | undefined,
    config?:
      | {
          fractionDigits?: number;
        }
      | undefined
  ) => string | null;
};

const useCurrency = create<CurrencyState>((set, get) => ({
  currency: CurrencyKey.hnt,
  currencyOptions: map(currencies, ({ title }, key) => ({ key, title })),
  setCurrency: (newCurrency) => set({ currency: newCurrency }),
  getCurrencyTitle: (currencyKey) =>
    currencies[currencyKey]?.title || toUpper(currencyKey),
  getCurrencyOption: (currencyKey) => currencies[currencyKey],
  currencyDisplay: (
    // TODO: see if this can be replaced by displayInCurrency from the TokensProvider
    currency: CurrencyKey,
    amount: number | undefined,
    { fractionDigits = 2 } = {
      fractionDigits: 2
    }
  ) => {
    if (amount == null) return null;

    return amount.toLocaleString("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    });
  }
}));

export default useCurrency;
