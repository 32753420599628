import {
  EventData,
  GeolocateControl,
  Map,
  MapboxEvent,
  NavigationControl
} from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "react";
import { Source } from "react-mapbox-gl";
import useMapCenter from "../../../utils/useMapCenter";
import HotspotLayer from "./Layers/HotspotLayer";

interface Props {
  map: Map;
  containerStyle: React.CSSProperties;
  isMoving: boolean;
}

const MapContent: React.FC<Props> = ({ map, containerStyle, isMoving }) => {
  const [setCenter] = useMapCenter((state) => [state.setCenter]);
  const setZoom = useMapCenter((state) => state.setZoom);
  const [flyingComplete, setFlyingComplete] = useState<boolean>(false);

  useEffect(() => {
    map.touchZoomRotate.disableRotation();
    map.touchPitch.disable();
    map.addControl(
      new GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: true
      })
    );
    map.addControl(
      new NavigationControl({
        showCompass: false,
        showZoom: true
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    map.resize();
  }, [containerStyle, map]);

  useEffect(() => {
    let flying = false;

    if (!flyingComplete) return;

    if (flying) {
      map.once(
        "moveend",
        (
          event: MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined> &
            EventData
        ) => {
          flying = false;
          setFlyingComplete(true);
          setZoom((event.target as any).transform.tileZoom);
        }
      );
    } else {
      map.fire("flystart");
      flying = true;
    }
  }, [flyingComplete, map, setCenter, setZoom]);

  return (
    <>
      <Source
        id="mapbox-terrain"
        tileJsonSource={{
          type: "vector",
          url: "mapbox://mapbox.mapbox-terrain-v2",
          attribution: "Mapbox Terrain tileset v2"
        }}
      />

      <HotspotLayer map={map} />
    </>
  );
};

export default MapContent;
