import moment from "moment";
import React, { useMemo } from "react";
import MemberAvatarImg from "../../../context/members/MemberAvatarImg";
import classNames from "../../../utils/classNames";
import { Role } from "../../../utils/constants";
import { getMessageContentJsx } from "../../../utils/messageToJsx";
import { Comment, Member } from "../../../utils/types";

export interface Props {
  className?: string;
  comment: Comment;
}

const CommentComponent: React.FC<Props> = ({ className, comment }) => {
  const createdAt = useMemo(
    () => moment(comment.created_at).utc().fromNow(),
    [comment.created_at]
  );

  const updatedAt = useMemo(
    () => moment(comment.updated_at).utc().fromNow(),
    [comment.updated_at]
  );

  const member: Member = useMemo(
    () => ({
      name: comment.user_name || comment.contact_name || "System",
      email: comment.user_email || comment.email || "",
      uuid: comment.user_uuid || comment.contact_uuid || "",
      avatar_url: comment.user_avatar || "",
      pending: false,
      role: Role.member,
      deleted_at: ""
    }),
    [
      comment.contact_name,
      comment.contact_uuid,
      comment.email,
      comment.user_avatar,
      comment.user_email,
      comment.user_name,
      comment.user_uuid
    ]
  );

  const message = useMemo(
    () => getMessageContentJsx(comment.message),
    [comment.message]
  );

  return (
    <div
      id={`#${comment.uuid}`}
      className={classNames("flex space-x-3", className)}
    >
      <div className="flex-shrink-0 w-10 h-10">
        <MemberAvatarImg member={member} sizeInPx={40} />
      </div>

      <div>
        <div className="text-sm">
          <span className="font-medium text-gray-900 dark:text-white mr-1">
            {member?.name}
          </span>
          <span className="font-medium text-gray-500 mx-1">
            {createdAt}
            {comment.updated_at &&
              comment.updated_at !== comment.created_at &&
              ` • edited ${updatedAt}`}
          </span>
        </div>

        <div className="mt-1 text-sm text-gray-700 dark:text-white">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default CommentComponent;
