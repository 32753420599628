import { capitalize } from "lodash";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { AppearanceProvider } from "./appearance";
import { AuthenticationProvider } from "./authentication";
import { GoogleAnalyticsProvider } from "./googleAnalytics";
import { StatsProvider } from "./stats";
import { ToastProvider } from "./toast";
import { TokensProvider } from "./tokens";
import { ViewportProvider } from "./viewport";
import { WorkspacesProvider } from "./workspaces";

interface Props {}

const AppContext: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  const title = useMemo(() => {
    const titleSections = location.pathname
      .split("/")
      .filter((item) => item.length && item.length < 14)
      .map(capitalize);

    return ["Hotspotty"].concat(titleSections).join(" - ");
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GoogleAnalyticsProvider>
        <TokensProvider>
          <StatsProvider>
            <AppearanceProvider>
              <ToastProvider delay={3000}>
                <AuthenticationProvider>
                  <WorkspacesProvider>
                    <ViewportProvider>{children}</ViewportProvider>
                  </WorkspacesProvider>
                </AuthenticationProvider>
              </ToastProvider>
            </AppearanceProvider>
          </StatsProvider>
        </TokensProvider>
      </GoogleAnalyticsProvider>
    </>
  );
};

export default AppContext;
