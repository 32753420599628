import { filter, isEmpty, values } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ApiResponse, fetchApi } from "../../utils/api";
import { Workspace } from "../../utils/types";
import { useAuthentication } from "../authentication";
import useWorkspaceState from "./useWorkspaceState";

interface WorkspacesMap {
  [workspaceId: string]: Workspace;
}

export type ContextValue = {
  workspaces: WorkspacesMap;
  loadWorkspaces: () => Promise<void>;
  setWorkspaces: React.Dispatch<React.SetStateAction<WorkspacesMap>>;
  currentWorkspace: Workspace | null;
  setCurrentWorkspace: React.Dispatch<React.SetStateAction<Workspace | null>>;
};

export const WorkspacesContext = React.createContext<ContextValue | undefined>(
  undefined
);

interface Props {}

export const WorkspacesProvider: React.FC<Props> = ({
  children,
  ...otherProps
}) => {
  const { authenticationState } = useAuthentication();
  const [workspaces, setWorkspaces] = useState<WorkspacesMap>({});
  const { search, hash } = useLocation();
  const history = useHistory();
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(
    null
  );
  const lastWorkspaceId = useWorkspaceState(
    (state) => state.currentWorkspaceId
  );
  const setLastWorkspaceId = useWorkspaceState(
    (state) => state.setCurrentWorkspaceId
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const workspace =
      searchParams.get("workspace") ||
      sessionStorage.getItem("lastWorkspaceBySearch");
    const hashParam = hash || sessionStorage.getItem("lastHash") || "";

    if (!workspace) return;

    sessionStorage.setItem("lastWorkspaceBySearch", workspace);
    sessionStorage.setItem("lastHash", hashParam);

    if (!workspaces[workspace] || currentWorkspace?.uuid === workspace) {
      return;
    }

    setCurrentWorkspace(workspaces[workspace]);

    searchParams.delete("workspace");
    history.replace({
      hash: hashParam || "",
      search: searchParams.toString()
    });

    sessionStorage.removeItem("lastWorkspaceBySearch");
    sessionStorage.removeItem("lastHash");
  }, [currentWorkspace, hash, history, search, workspaces]);

  const loadWorkspaces = useCallback(async () => {
    const response = await fetchApi("/api/workspaces");

    const apiResponse: ApiResponse<Workspace[]> = await response.json();

    if (!apiResponse.status) return;

    const newWorkspaces: WorkspacesMap = {};

    apiResponse.data.forEach((item) => {
      const filteredWorkspaces = filter(newWorkspaces, { uuid: item.uuid });

      if (!isEmpty(filteredWorkspaces)) {
        item.name = `${item.name} [${filteredWorkspaces.length + 1}]`;
      }

      newWorkspaces[`${item.uuid}-${item.contact_uuid}`] = item;
    });

    setWorkspaces(newWorkspaces);

    if (currentWorkspace) return;

    if (lastWorkspaceId && newWorkspaces[lastWorkspaceId]) {
      setCurrentWorkspace(newWorkspaces[lastWorkspaceId]);
      return;
    }

    if (!isEmpty(newWorkspaces)) {
      setCurrentWorkspace(values(newWorkspaces).shift() || null);
      return;
    }
  }, [currentWorkspace, lastWorkspaceId]);

  useEffect(() => {
    if (authenticationState !== "authenticated" && currentWorkspace?.uuid) {
      setCurrentWorkspace(null);
    }

    if (authenticationState !== "authenticated" || currentWorkspace?.uuid)
      return;

    loadWorkspaces();
  }, [authenticationState, currentWorkspace?.uuid, loadWorkspaces]);

  useEffect(() => {
    if (!currentWorkspace || currentWorkspace.uuid === lastWorkspaceId) return;

    setLastWorkspaceId(currentWorkspace?.uuid);
  }, [currentWorkspace, lastWorkspaceId, setLastWorkspaceId]);

  const value = useMemo(
    () => ({
      workspaces,
      loadWorkspaces,
      currentWorkspace,
      setWorkspaces,
      setCurrentWorkspace
    }),
    [
      workspaces,
      loadWorkspaces,
      currentWorkspace,
      setWorkspaces,
      setCurrentWorkspace
    ]
  );

  return (
    <WorkspacesContext.Provider value={value} {...otherProps}>
      {children}
    </WorkspacesContext.Provider>
  );
};

export const useWorkspaces = (): ContextValue => {
  const context = useContext(WorkspacesContext);

  if (context === undefined) {
    throw new Error("useWorkspaces must be used within an WorkspacesProvider");
  }

  return context;
};
