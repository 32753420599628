import { get, isEmpty, reduce } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import useSWR from "swr";
import { BetaApiResponse } from "../../utils/api";
import { Subnetwork } from "../../utils/useNetwork";

type ContextValue = {
  stats: StatsMap | undefined;
  isLoading: boolean;
  isError: boolean;
  getPercentile: (
    subnetwork: Subnetwork,
    metric: string,
    value: number
  ) => number | undefined;
};

const StatsContext = React.createContext<ContextValue | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const StatsProvider: React.FC<Props> = (props) => {
  const fetchStats = useCallback(async (): Promise<StatsMap> => {
    const response = await fetch(
      `${process.env.REACT_APP_BETA_API_URL}/api/v1/stats`,
      {
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_BETA_API_TOKEN}`
        }
      }
    );

    const apiResponse: BetaApiResponse<StatsResponse> = await response.json();

    return reduce(
      apiResponse.data,
      (
        acc: StatsMap,
        {
          token_name,
          total_hotspots,
          active_hotspots,
          total_cities,
          total_countries,
          daily_average_rewards,
          histograms,
          histogram_percentiles
        },
        subnetwork: string
      ) => {
        acc[subnetwork] = {
          token: token_name,
          hotspots: total_hotspots,
          activeHotspots: active_hotspots,
          cities: total_cities,
          countries: total_countries,
          dailyAverageHotspotRewards: daily_average_rewards,
          histograms: histograms,
          percentiles: histogram_percentiles
        };
        return acc;
      },
      {}
    );
  }, []);

  const { data: stats, error: isError } = useSWR("stats", fetchStats, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false
  });

  const isLoading = useMemo(() => !isError && !stats, [isError, stats]);

  const getPercentile = useCallback(
    (subnetwork: string, metric: string, value: number) => {
      const percentileValues: number[] = get(
        subnetwork,
        ["percentiles", metric],
        []
      );

      if (isEmpty(percentileValues)) return undefined;

      let percentile = 0;

      percentileValues.forEach((percentileValue, index) => {
        if (value > percentileValue) {
          percentile = index + 1;
        }
      });

      return percentile;
    },
    []
  );

  const value = useMemo(
    () => ({ stats, isLoading, isError, getPercentile }),
    [stats, isLoading, isError, getPercentile]
  );

  return <StatsContext.Provider value={value} {...props} />;
};

export const useStats = (): ContextValue => {
  const context = useContext(StatsContext);
  if (context === undefined) {
    throw new Error("useStats must be used within an StatsProvider");
  }
  return context;
};

//
// Utils
//

export interface HistogramData {
  boundary: number;
  count: number;
}

interface HistogramValuesMap {
  inactive: HistogramData;
  terrible: HistogramData;
  poor: HistogramData;
  mediocre: HistogramData;
  good: HistogramData;
  excellent: HistogramData;
}

interface StatsResponse {
  [subnetwork: string]: {
    token_name: string;
    total_hotspots: number;
    active_hotspots: number;
    total_cities: number;
    total_countries: number;
    daily_average_rewards: number;
    histograms: {
      witnesses: HistogramValuesMap;
    };
    histogram_percentiles: {
      witnesses: number[];
    };
  };
}

interface NetworkStats {
  token: string;
  hotspots: number;
  activeHotspots: number;
  cities: number;
  countries: number;
  dailyAverageHotspotRewards: number;
  histograms: {
    witnesses: HistogramValuesMap;
  };
  percentiles: {
    witnesses: number[];
  };
}

interface StatsMap {
  [subnetwork: string]: NetworkStats;
}

export const renderPercentileClassification = (
  percentile: number | undefined
) => {
  if (percentile == null) return;

  if (percentile === 0) return "inactive";

  if (percentile < 50) return `bottom ${percentile}%`;
  return `top ${Math.max(100 - percentile, 1)}%`;
};
