import moment from "moment";

export const EXPORT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const EXPORT_DATE_FORMAT = "YYYY-MM-DD";
export const MONTH_FORMAT = "MMMM YYYY";

export const CURRENT_CALENDAR_MONTH_DISPLAY = moment()
  .utc()
  .format(MONTH_FORMAT);
export const PREVIOUS_CALENDAR_MONTH_DISPLAY = moment()
  .utc()
  .subtract(1, "month")
  .format(MONTH_FORMAT);

export const formatDateToLocale = (date: string, addTime?: boolean) => {
  const day = moment(date).utc().format("DD");
  const month = moment(date).utc().format("MM");
  const year = moment(date).utc().format("YYYY");
  const hour = moment(date).utc().format("HH");
  const minute = moment(date).utc().format("mm");

  const isCurrentYear = year === moment().utc().year().toString();
  const UTCDateTime = `${day}-${month}-${year} ${hour}:${minute}`;

  const formattedDate = moment(UTCDateTime, "DD-MM-YYYY HH:mm")
    .utc()
    .toDate()
    .toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: !isCurrentYear ? "numeric" : undefined,
      hour: addTime ? "2-digit" : undefined,
      minute: addTime ? "2-digit" : undefined,
      hour12: false
    });

  if (!isCurrentYear && !addTime) return formattedDate.replace(",", "");

  return formattedDate;
};

export const locale = Intl.DateTimeFormat().resolvedOptions().locale;

export const renderDate = (timestamp: number | null) => {
  if (!timestamp) return;

  return `${formatDateToLocale(
    moment.unix(timestamp).utc().toLocaleString()
  )} (${moment.unix(timestamp).fromNow()})`;
};