export const accountPeopleStorageKey = "hotspotty_account_people";

export const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280
};

export enum Entity {
  hotspot = "hotspot",
  location = "location",
  hex = "hex",
  wallet = "wallet",
  contact = "contact",
  install = "install",
  label = "label",
  commissionTemplate = "commissionTemplate",
  commissionReport = "commissionReport",
  contactCommissions = "contactCommissions",
  transaction = "transaction",
  contactPayment = "contactPayment"
}

export const entityPaths = {
  [Entity.hotspot]: "/hotspots",
  [Entity.location]: "/locations",
  [Entity.hex]: "/hexes"
};

export enum Role {
  admin = "admin",
  member = "member"
}

export const avatarsApi = process.env.REACT_APP_AVATARS_API;

export const DEFAULT_HEIGHT_IN_METERS = 0;
