import {
  filter,
  find,
  isEmpty,
  map,
  sumBy,
  uniq,
  values,
  without
} from "lodash";
import Tooltip from "rc-tooltip";
import React, { useCallback, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import InfoIcon from "../../../components/icons/InfoIcon";
import { useContacts } from "../../../context/contacts";
import { useInstalls } from "../../../context/installs";
import { getContactInstallCommissions } from "../../../context/installs/utils";
import { useTokens } from "../../../context/tokens";
import { ContactPayment } from "../../../utils/types";
import useCurrency, { CurrencyKey } from "../../../utils/useCurrency";
import useNetwork from "../../../utils/useNetwork";
import ContactPaymentSidebar from "../../Workspace/common/ContactPaymentSidebar";

const ContactPaymentsTab: React.FC<RouteComponentProps<{ id: string }>> = (
  props
) => {
  const { installs } = useInstalls();
  const { getCurrencyTitle, currencyDisplay } = useCurrency();
  const { displayInCurrency } = useTokens();
  const { networkItem } = useNetwork();
  const [isContactPaymentSidebarOpen, setIsContactPaymentSidebarOpen] =
    useState(false);
  const [currentContactPayment, setCurrentContactPayment] =
    useState<ContactPayment | null>(null);
  const { contactDetails } = useContacts();

  const contactPayments = useMemo(
    () => contactDetails?.contact_payments || [],
    [contactDetails?.contact_payments]
  );

  const contactCurrencies = useMemo(() => {
    const contactInstalls = values(installs).filter(
      ({ commissions }) =>
        !!find(commissions, { contact_uuid: props.match.params.id })
    );

    if (!props.match.params.id) return [];

    const contactCommissions = map(
      getContactInstallCommissions(props.match.params.id, contactInstalls),
      "commission"
    );

    let relevantCurrencies = map(contactCommissions, "currency").concat(
      map(contactPayments, "currency")
    );

    if (relevantCurrencies.includes(CurrencyKey.tokens)) {
      relevantCurrencies = without(
        relevantCurrencies,
        CurrencyKey.tokens
      ).concat(networkItem.tokens);
    }

    return uniq(relevantCurrencies);
  }, [contactPayments, installs, networkItem.tokens, props.match.params.id]);

  const getPaidAmountByCurrency = useCallback(
    (currency: CurrencyKey) =>
      sumBy(filter(contactPayments, { currency }), "amount"),
    [contactPayments]
  );

  const renderPaymentsRow = useMemo(() => {
    return (
      <>
        {contactPayments.map((contactPayment, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                {contactPayment.paid_at}
              </p>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                {networkItem.tokens.includes(contactPayment.currency)
                  ? displayInCurrency(contactPayment.amount, {
                      withTokenSuffix: true,
                      displayCurrency: contactPayment.currency
                    })
                  : currencyDisplay(
                      contactPayment.currency,
                      contactPayment.amount
                    )}
              </p>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                {getCurrencyTitle(contactPayment.currency)}
              </p>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <p className="text-sm font-medium text-gray-900 dark:text-white group-hover:underline">
                {contactPayment.notes}
              </p>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <button
                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-900 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-400"
                onClick={() => {
                  setCurrentContactPayment(contactPayment);
                  setIsContactPaymentSidebarOpen(true);
                }}
              >
                Open
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  }, [
    contactPayments,
    currencyDisplay,
    displayInCurrency,
    getCurrencyTitle,
    networkItem.tokens
  ]);

  return (
    <>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {!isEmpty(contactCurrencies) && (
          <div className="mt-5 mb-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
            {contactCurrencies.map((currency) => {
              const paidAmount = getPaidAmountByCurrency(currency);

              return (
                <div
                  key={currency}
                  className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg"
                >
                  <div className="px-4 py-5 sm:p-6">
                    <dt className="text-sm font-medium text-gray-500 dark:text-gray-300 truncate">
                      Payments in {getCurrencyTitle(currency)}
                    </dt>

                    <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
                      {networkItem.tokens.includes(currency)
                        ? displayInCurrency(paidAmount, {
                            withTokenSuffix: true,
                            displayCurrency: currency
                          })
                        : currencyDisplay(currency, paidAmount)}
                    </dd>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="w-full align-middle inline-block min-w-full mt-6">
          {!isEmpty(contactPayments) ? (
            <>
              <div className="shadow w-full overflow-x-auto sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-500">
                  <thead className="bg-gray-50 text-gray-500 dark:bg-gray-700 dark:text-gray-300">
                    <tr>
                      <th
                        scope="col"
                        style={{ minWidth: "9rem" }}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Payment date
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "9rem" }}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "9rem" }}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Currency
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "9rem" }}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Notes
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "9rem" }}
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider"
                      >
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 text-gray-500 dark:text-gray-50 dark:divide-gray-500 dark:bg-gray-800">
                    {renderPaymentsRow}
                  </tbody>
                </table>
              </div>

              <ContactPaymentSidebar
                contactPayment={currentContactPayment}
                open={isContactPaymentSidebarOpen}
                onClose={() => {
                  setIsContactPaymentSidebarOpen(false);
                  setCurrentContactPayment(null);
                }}
              />
            </>
          ) : (
            <div className="flex pt-6">
              <p className="text-sm text-gray-500 dark:text-gray-300">
                No registered payments are available.
                <Tooltip
                  placement="top"
                  overlay="This does not mean that no payments were done, it just means that any payments that were done were not registered in the system by the administrator."
                >
                  <InfoIcon className="inline-flex h-4 w-4 ml-1" />
                </Tooltip>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactPaymentsTab;
