import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import InfoItems from "../../../components/InfoItems";
import { useContacts } from "../../../context/contacts";
import { useWorkspaces } from "../../../context/workspaces";

import PaymentMethodComponent from "../../Workspace/common/PaymentMethodComponent";

const ContactInfoTab: React.FC = () => {
  const { currentWorkspace } = useWorkspaces();
  const { contactDetails } = useContacts();

  const renderPaymentMethods = useMemo(
    () => (
      <div className="mt-2 flex flex-col w-full shadow overflow-hidden sm:rounded-lg">
        <div className="bg-white divide-y divide-gray-200 text-gray-500 dark:text-gray-50 dark:divide-gray-500 dark:bg-gray-800">
          {contactDetails?.payment_methods.map(
            (paymentMethod, paymentIndex) => (
              <div
                key={paymentIndex}
                className="px-6 py-4 whitespace-nowraprelative flex items-center space-x-3 group"
              >
                <div className="flex-1 flex-col min-w-0 focus:outline-none">
                  <PaymentMethodComponent paymentMethod={paymentMethod} />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    ),
    [contactDetails?.payment_methods]
  );

  const userInfos = useMemo(
    () => [
      { label: "Email", value: currentWorkspace?.contact_email },
      {
        label: (
          <div className="mb-2 flex justify-between items-center">
            <h3 className="text-sm font-medium text-gray-500">
              Payment methods
            </h3>
          </div>
        ),
        value: isEmpty(contactDetails?.payment_methods)
          ? null
          : renderPaymentMethods
      },
      { label: "Phone", value: currentWorkspace?.contact_phone },
      { label: "VAT", value: currentWorkspace?.contact_vat }
    ],
    [
      contactDetails?.payment_methods,
      currentWorkspace?.contact_email,
      currentWorkspace?.contact_phone,
      currentWorkspace?.contact_vat,
      renderPaymentMethods
    ]
  );

  return (
    <>
      <InfoItems infoItems={userInfos} />
    </>
  );
};

export default ContactInfoTab;
