import React from "react";
import { useGoogleAnalytics } from "../googleAnalytics";
import useAppearanceState, { ThemeKey } from "./useAppearanceState";

interface Props {
  className?: string;
}

const ThemeSelector: React.FC<Props> = ({ className }) => {
  const { trackEvent } = useGoogleAnalytics();
  const theme = useAppearanceState((state) => state.theme);
  const setTheme = useAppearanceState((state) => state.setTheme);
  const themeOptions = useAppearanceState((state) => state.themeOptions);

  return (
    <div className={className}>
      <select
        id="theme"
        className="w-full pl-3 py-2 sm:text-sm rounded-md dark:bg-gray-800"
        onChange={(e) => {
          trackEvent("update theme", {
            event_category: "user settings",
            event_label: e.target.value
          });
          setTheme(e.target.value as ThemeKey);
        }}
        value={theme}
      >
        {themeOptions.map(({ key, title }) => (
          <option key={key} value={key}>
            {title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ThemeSelector;
