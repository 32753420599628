import React from "react";
import classNames from "../utils/classNames";
import LoadingIcon from "./icons/LoadingIcon";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const CenteredLoading: React.FC<Props> = ({ className, ...otherProps }) => (
  <div
    className={classNames("flex justify-center items-center", className)}
    {...otherProps}
  >
    <LoadingIcon className="h-8 w-8 animate-spin text-purple-600 dark:text-white" />
  </div>
);

export default CenteredLoading;
