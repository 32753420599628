import { forEach, keyBy, values } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { fetchApi } from "../../utils/api";
import { DataById, EtlHotspot, PhpHotspot } from "../../utils/types";
import { useWorkspaces } from "../workspaces";

type ContextValue = {
  hotspotsMap: {
    [hotspotId: string]: PhpHotspot;
  };
  getHotspotCoordinates: (
    hotspotId: string
  ) => { h3Index: string; lat: number; lng: number } | undefined;
};

const HotspotsContext = React.createContext<ContextValue | undefined>(
  undefined
);

interface Props {}

export const HotspotsProvider: React.FC<Props> = ({
  children,
  ...otherProps
}) => {
  const { currentWorkspace } = useWorkspaces();
  const [workspaceHotspotsMap, setWorkspaceHotspotsMap] = useState<{
    [id: string]: PhpHotspot;
  }>({});

  const hotspotsMap = useMemo(() => {
    let result: DataById<PhpHotspot> = {};

    forEach(workspaceHotspotsMap, (hotspot, id) => {
      result[id] = hotspot;
    });

    return result;
  }, [workspaceHotspotsMap]);

  const fetchWorkspaceHotspots = useCallback(async () => {
    if (!currentWorkspace) return;

    const res = await fetchApi(
      `/api/workspace/${currentWorkspace.uuid}/hotspots/${currentWorkspace.contact_uuid}`
    );
    const response: ListWorkspaceHotspotsResponse = await res.json();

    const workspaceHotspotsMap = keyBy(
      response.data.map(
        ({ etl_information, total_messages, verified_by, address }) => ({
          ...etl_information,
          total_messages,
          verified_by,
          address
        })
      ),
      "id"
    );

    setWorkspaceHotspotsMap(workspaceHotspotsMap);

    return values(workspaceHotspotsMap);
  }, [currentWorkspace]);

  useEffect(() => {
    fetchWorkspaceHotspots();
  }, [fetchWorkspaceHotspots]);

  // This takes into account the time travel date
  const getHotspotCoordinates = useCallback(
    (hotspotId: string): HotspotCoordinates | undefined => {
      const hotspot = hotspotsMap[hotspotId];

      if (!hotspot || !hotspot.location || !hotspot.lat || !hotspot.lng) return;

      return {
        lat: hotspot.lat,
        lng: hotspot.lng,
        h3Index: hotspot.location
      };
    },
    [hotspotsMap]
  );

  const value = useMemo(
    () => ({
      hotspotsMap,
      getHotspotCoordinates
    }),
    [hotspotsMap, getHotspotCoordinates]
  );

  return (
    <HotspotsContext.Provider value={value} {...otherProps}>
      {children}
    </HotspotsContext.Provider>
  );
};

export const useHotspots = (): ContextValue => {
  const context = useContext(HotspotsContext);
  if (context === undefined) {
    throw new Error("useHotspots must be used within an HotspotsProvider");
  }
  return context;
};

//
// Utils
//

interface HotspotCoordinates {
  h3Index: string;
  lat: number;
  lng: number;
}

export interface PhpHotspotResponse {
  address: string;
  created_at: string;
  created_by: string;
  current_status: "online";
  deleted_at: string | null;
  etl_information: EtlHotspot;
  id: number;
  name: string;
  sync_at: string;
  total_messages: number;
  updated_at: string;
  updated_by: string | null;
  uuid: string;
  wallet_owner: string;
  verified_by: string | null; // The workspace uuid
}

interface ListWorkspaceHotspotsResponse {
  status: true;
  message: string;
  data: PhpHotspotResponse[];
}
