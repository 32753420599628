import { head } from "lodash";
import { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useState } from "react";
import ReactMapboxGl, { MapContext } from "react-mapbox-gl";
import CenteredLoading from "../../../components/CenteredLoading";
import useMapCenter, { Coordinates } from "../../../utils/useMapCenter";
import useMapConfig, { VisualizationLayer } from "../../../utils/useMapConfig";
import MapContent from "./MapContent";

const Mapbox = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_KEY ?? "",
  interactive: true,
  minZoom: 2,
  logoPosition: "top-left"
});

interface Props {
  containerStyle?: React.CSSProperties;
}

const MapComponent: React.FC<Props> = ({ containerStyle = {} }) => {
  const center = useMapCenter((state) => state.center);
  const setCenter = useMapCenter((state) => state.setCenter);
  const setBoundaries = useMapCenter((state) => state.setBoundaries);
  const zoom = useMapCenter((state) => state.zoom);
  const visibleLayers = useMapConfig((state) => state.visibleLayers);
  const [isMoving, setIsMoving] = useState(false);

  const onMoveAndResize = useCallback(
    (map: Map, event: React.SyntheticEvent<any>) => {
      const { lng, lat } = map.getCenter();
      const newCenter: Coordinates = [lng, lat];

      try {
        const [neLng, neLat] = map.getBounds().getNorthEast().toArray();
        const [swLng, swLat] = map.getBounds().getSouthWest().toArray();

        setCenter(newCenter);
        setBoundaries({
          ne: [neLng, neLat],
          sw: [swLng, swLat]
        });

        const mapIdle = () => {
          if (map.areTilesLoaded()) {
            map.off("moveend", mapIdle);
            map.on("moveend", mapIdle);
          }
        };

        mapIdle();
      } catch {}
    },
    [setCenter, setBoundaries]
  );

  const getMapboxStyleUrl = () => {
    let key = document.documentElement.classList.contains("dark")
      ? "ckshalgloh40l17q6aapw2lp9"
      : "ckshap8do7p1617rzndourdz2";

    if (visibleLayers[VisualizationLayer.satellite]) {
      key = document.documentElement.classList.contains("dark")
        ? "ckvb63ua515yi14qig2qsyfbu"
        : "ckt5sbvz52byi17qogc71dm3p";
    }

    return `mapbox://styles/hotspotty/${key}`;
  };

  if (!head(center)) {
    return <div style={{ ...mapboxContainerStyle, ...containerStyle }} />;
  }

  return (
    <Mapbox
      style={getMapboxStyleUrl()}
      containerStyle={{ ...mapboxContainerStyle, ...containerStyle }}
      center={center}
      zoom={zoom}
      onMoveEnd={onMoveAndResize}
      onResize={onMoveAndResize}
      onDragStart={() => setIsMoving(true)}
      onDragEnd={() => setIsMoving(false)}
    >
      <MapContext.Consumer>
        {(map) =>
          map ? (
            <MapContent
              map={map}
              containerStyle={containerStyle}
              isMoving={isMoving}
            />
          ) : (
            <CenteredLoading className="h-full" />
          )
        }
      </MapContext.Consumer>
    </Mapbox>
  );
};

export default MapComponent;

//
// Utils
//

const mapboxContainerStyle: React.CSSProperties = {
  position: "relative",
  width: "100%",
  overflow: "hidden",
  height: "100%"
};
