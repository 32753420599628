import { Transition } from "@headlessui/react";
import React, { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";
import { Notification } from "./ToastProvider";

interface Props {
  notification: Notification;
  onClose: () => void;
  show: boolean;
}

const Toast: React.FC<Props> = ({
  notification: { title, message, to },
  onClose,
  show
}) => {
  const renderContent = useMemo(() => {
    return (
      <div className="max-w-sm w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg pointer-events-auto border border-gray-200 dark:border-gray-400">
        <div className="rounded-lg shadow-sm overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="ml-3 w-0 flex-1 pt-0.5">
                {title && (
                  <div className="text-sm leading-5 font-medium text-gray-900 dark:text-white">
                    {title}
                  </div>
                )}
                {message && (
                  <div className="mt-1 text-sm leading-5 text-gray-500 dark:text-gray-200">
                    {message}
                  </div>
                )}
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  onClick={(e) => {
                    // prevent click from bubbling up and triggering "to"
                    e.stopPropagation();
                    onClose();
                  }}
                  className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                >
                  <svg
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [title, message, onClose]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-100 transform"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-75 transform"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="inset-0 flex items-end justify-center first:pt-0 pointer-events-none sm:items-start sm:justify-end">
        {to ? (
          <Link className="w-full" to={to}>
            {renderContent}
          </Link>
        ) : (
          renderContent
        )}
      </div>
    </Transition>
  );
};

export default Toast;
