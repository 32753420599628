import { find, keyBy, values } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ApiResponse, fetchApi } from "../../utils/api";
import { Install, InstallCommission } from "../../utils/types";
import useMapCenter from "../../utils/useMapCenter";
import usePrevious from "../../utils/usePrevious";
import { useContacts } from "../contacts";
import { useWorkspaces } from "../workspaces";
import { isActiveInstall } from "./utils";

interface InstallsMap {
  [installId: string]: Install;
}

type ContextValue = {
  installs: InstallsMap;
  activeInstallsForCurrentContact: Install[];
  fetchInstalls: () => Promise<InstallsMap>;
  currentlyEditedInstall: Install | undefined;
  setCurrentlyEditedInstall: (install: Install | undefined) => void;
};

const InstallsContext = React.createContext<ContextValue | undefined>(
  undefined
);

interface Props {}

export const InstallsProvider: React.FC<Props> = ({
  children,
  ...otherProps
}) => {
  const { currentWorkspace } = useWorkspaces();
  const { contactDetails } = useContacts();
  const [installs, setInstalls] = useState<InstallsMap>({});
  const [currentlyEditedInstall, setCurrentlyEditedInstall] = useState<
    Install | undefined
  >();
  const setCenter = useMapCenter((state) => state.setCenter);

  const activeInstallsForCurrentContact = useMemo(
    () =>
      values(installs).filter(
        (install) =>
          !!contactDetails &&
          (contactDetails?.linked_location_ids.includes(
            install.location_uuid
          ) ||
            !!find(install.commissions, {
              contact_uuid: contactDetails.uuid
            })) &&
          isActiveInstall(install)
      ),
    [contactDetails, installs]
  );

  const fetchInstalls = useCallback(async () => {
    if (!currentWorkspace) return {};

    const res = await fetchApi(
      `/api/workspace/${currentWorkspace.uuid}/installs/${currentWorkspace.contact_uuid}`
    );

    const apiResponse: ApiResponse<Install[]> = await res.json();

    let newInstalls = {};

    if (apiResponse.status) {
      newInstalls = keyBy(apiResponse.data, "uuid");

      setInstalls(newInstalls);

      if (apiResponse.data.length > 0) {
        const firstInstall = Object.values(apiResponse.data)[0];
        setCenter([firstInstall.location_lng, firstInstall.location_lat]);
      }
    }

    return newInstalls;
  }, [currentWorkspace, setCenter]);

  const prevWorkspaceId = usePrevious(currentWorkspace?.uuid);
  useEffect(() => {
    if (
      currentWorkspace?.uuid &&
      prevWorkspaceId &&
      currentWorkspace.uuid !== prevWorkspaceId
    ) {
      setInstalls({});
    }
  }, [currentWorkspace, prevWorkspaceId]);

  useEffect(() => {
    fetchInstalls();
  }, [fetchInstalls]);

  const value = useMemo(
    () => ({
      installs,
      fetchInstalls,
      currentlyEditedInstall,
      setCurrentlyEditedInstall,
      activeInstallsForCurrentContact
    }),
    [
      installs,
      fetchInstalls,
      currentlyEditedInstall,
      setCurrentlyEditedInstall,
      activeInstallsForCurrentContact
    ]
  );

  return (
    <InstallsContext.Provider value={value} {...otherProps}>
      {children}
    </InstallsContext.Provider>
  );
};

export const useInstalls = (): ContextValue => {
  const context = useContext(InstallsContext);
  if (context === undefined) {
    throw new Error("useInstalls must be used within an InstallsProvider");
  }
  return context;
};

//
// Utils
//

export interface InstallValues {
  hotspot_uuid: string;
  location_uuid: string;
  installed_at: string | null; // e.g. "2021-08-04"
  uninstalled_at: string | null; // e.g. "2021-08-04"
  commissions: InstallCommission[];
}
