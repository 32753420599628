import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { useInstalls } from "../../../../context/installs";
import { getContactInstallCommissions } from "../../../../context/installs/utils";
import { useWorkspaces } from "../../../../context/workspaces";
import ContactCommissionsTable from "./ContactCommissionsTable";

const ContactCommissionsTab: React.FC = () => {
  const { activeInstallsForCurrentContact } = useInstalls();
  const { currentWorkspace } = useWorkspaces();

  const currentCommissions = useMemo(
    () =>
      getContactInstallCommissions(
        `${currentWorkspace?.contact_uuid}`,
        activeInstallsForCurrentContact
      ),
    [currentWorkspace?.contact_uuid, activeInstallsForCurrentContact]
  );

  return (
    <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="w-full mb-6 align-middle inline-block">
        {!isEmpty(currentCommissions) ? (
          <ContactCommissionsTable items={currentCommissions} />
        ) : (
          <p className="text-sm text-gray-500 dark:text-gray-300">
            There are no active commissions associated with this contact.
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactCommissionsTab;
