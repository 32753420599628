import { stringifyUrl } from "query-string";
import React from "react";
import { avatarsApi } from "../../utils/constants";
import { Member, MessageUserData } from "../../utils/types";
import { getMemberAvatarUrl } from "./utils";

interface Props {
  member: Member | MessageUserData | undefined;
  contactUuid?: string;
  sizeInPx: number;
}

const MemberAvatarImg: React.FC<Props> = ({
  member,
  sizeInPx,
  contactUuid
}) => (
  <img
    className="rounded-full"
    src={
      member
        ? getMemberAvatarUrl(member, sizeInPx)
        : stringifyUrl({
            url: `${avatarsApi}/human/${contactUuid}.svg`
          })
    }
    onError={(e: any) => {
      e.target.onerror = null;
      e.target.src = getMemberAvatarUrl(member, sizeInPx, true);
    }}
    alt="user avatar"
  />
);

export default MemberAvatarImg;
