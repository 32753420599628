import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import CenteredLoading from "../components/CenteredLoading";
import { useAuthentication } from "../context/authentication/AuthenticationProvider";
import { CommentsProvider } from "../context/comments";
import { CommissionReportsProvider } from "../context/commissionReports";
import { ContactsProvider } from "../context/contacts";
import { HotspotsProvider } from "../context/hotspots";
import { InstallsProvider } from "../context/installs";
import MapPage from "../pages/Map";

const PrivateRoute: React.FC = () => {
  const { authenticationState } = useAuthentication();

  if (authenticationState === "unauthenticated") {
    return <Redirect to={`/auth/login`} />;
  }

  if (authenticationState === "checking") {
    return <CenteredLoading className="sm:h-full h-96 pt-6" />;
  }

  return (
    <Router>
      <CommentsProvider>
        <ContactsProvider>
          <HotspotsProvider>
            <InstallsProvider>
              <CommissionReportsProvider>
                <Switch>
                  <Route
                    path={["/contacts", "/installs"]}
                    component={MapPage}
                  />
                  <Redirect path="" to="/contacts" />
                </Switch>
              </CommissionReportsProvider>
            </InstallsProvider>
          </HotspotsProvider>
        </ContactsProvider>
      </CommentsProvider>
    </Router>
  );
};

export default PrivateRoute;
