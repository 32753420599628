import { CogIcon } from "@heroicons/react/solid";
import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import MapAppearanceSettingsSidebar from "./MapAppearanceSettingsSidebar";
import WorkspaceAppearanceSettingsSidebar from "./WorkspaceAppearanceSettingsSidebar";

interface Props {
  className?: string;
}

const AppearanceSettingsButton: React.FC<Props> = ({ className }) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const workspaceAndAuthMatch = matchPath(location.pathname, {
    path: ["/workspace", "/auth"]
  });

  const AppearanceSettingsSidebar = workspaceAndAuthMatch
    ? WorkspaceAppearanceSettingsSidebar
    : MapAppearanceSettingsSidebar;

  return (
    <div className={className}>
      <Tooltip placement="bottom" overlay="Appearance settings">
        <button
          type="button"
          className="h-12 w-8 inline-flex items-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none dark:text-gray-200 dark:hover:text-white"
          onClick={() => setOpen(true)}
        >
          <CogIcon className="h-6 w-6" />
        </button>
      </Tooltip>

      <AppearanceSettingsSidebar open={open} onClose={() => setOpen(false)} />
    </div>
  );
};

export default AppearanceSettingsButton;
