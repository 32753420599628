import { find } from "lodash";
import create from "zustand";
import { CurrencyKey } from "./useCurrency";

export enum Network {
  helium = "helium"
}

export enum Subnetwork {
  heliumIot = "helium_iot",
  heliumMobile = "helium_mobile"
}

interface NetworkItem {
  title: string;
  value: Network;
  icon: CurrencyKey;
  disabled: boolean;
  subnetworks: Array<{
    id: Subnetwork;
    name: string;
  }>;
  tokens: CurrencyKey[];
}

export const NETWORKS: NetworkItem[] = [
  {
    title: "Helium",
    value: Network.helium,
    icon: CurrencyKey.hnt,
    disabled: false,
    subnetworks: [
      {
        id: Subnetwork.heliumIot,
        name: "Helium IoT"
      },
      {
        id: Subnetwork.heliumMobile,
        name: "Helium Mobile"
      }
    ],
    tokens: [CurrencyKey.iot, CurrencyKey.mobile, CurrencyKey.hnt]
  }
];

const getNetworkItem = (network: Network) =>
  find(NETWORKS, { value: network })!;

type NetworkConfig = {
  network: Network;
  networkItem: NetworkItem;
  token: CurrencyKey;
  setToken: (token: CurrencyKey) => void;
  setNetwork: (network: Network) => void;
};

const useNetwork = create<NetworkConfig>((set, get) => ({
  network: Network.helium,
  networkItem: getNetworkItem(Network.helium),
  token: CurrencyKey.iot,
  setToken: (token) => set({ token }),
  setNetwork: (network) => {
    if (network === get().network) return;
    const networkItem = getNetworkItem(network);
    set({ network, networkItem, token: networkItem.tokens[0] });
  }
}));

export default useNetwork;
