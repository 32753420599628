import { keyBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ApiResponse, fetchApi } from "../../utils/api";
import { CommissionReport, DataById } from "../../utils/types";
import { useWorkspaces } from "../workspaces";

interface Props {}

export type ContextValue = {
  commissionReports: DataById<CommissionReport>;
  fetchCommissionReports: () => Promise<void>;
};

export const CommissionReportsContext = React.createContext<
  ContextValue | undefined
>(undefined);

export const CommissionReportsProvider: React.FC<Props> = ({
  children,
  ...otherProps
}) => {
  const { currentWorkspace } = useWorkspaces();
  const [commissionReports, setComissionsReports] = useState<{
    [id: string]: CommissionReport;
  }>({});

  const fetchCommissionReports = useCallback(async () => {
    if (!currentWorkspace) return;

    try {
      const response = await fetchApi(
        `/api/workspace/${currentWorkspace.uuid}/reports/${currentWorkspace.contact_uuid}`
      );
      const apiResponse: ApiResponse<CommissionReport[]> =
        await response.json();

      if (apiResponse.status) {
        setComissionsReports(keyBy(apiResponse.data, "uuid"));
      }
    } catch {}
  }, [currentWorkspace]);

  useEffect(() => {
    fetchCommissionReports();
  }, [fetchCommissionReports]);

  const value = useMemo(
    () => ({
      commissionReports,
      fetchCommissionReports
    }),
    [commissionReports, fetchCommissionReports]
  );

  return (
    <CommissionReportsContext.Provider value={value} {...otherProps}>
      {children}
    </CommissionReportsContext.Provider>
  );
};

export const useCommissionReports = (): ContextValue => {
  const context = useContext(CommissionReportsContext);

  if (context === undefined) {
    throw new Error(
      "useCommissionReports must be used within an CommissionReportsProvider"
    );
  }

  return context;
};
