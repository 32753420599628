import { map } from "lodash";
import create from "zustand";
import { persist } from "zustand/middleware";

export enum ThemeKey {
  system = "system",
  light = "light",
  dark = "dark"
}

const themes = {
  [ThemeKey.system]: { title: "Use system settings" },
  [ThemeKey.light]: { title: "Light" },
  [ThemeKey.dark]: { title: "Dark" }
};

type AppearanceState = {
  theme: ThemeKey;
  setTheme: (newTheme: ThemeKey) => void;
  themeOptions: Array<{ key: string; title: string }>;
};

const useAppearanceState = create<AppearanceState>(
  persist(
    (set, get) => ({
      theme: ThemeKey.system,
      setTheme: (newTheme) => set({ theme: newTheme }),
      themeOptions: map(themes, ({ title }, key) => ({ key, title }))
    }),
    {
      name: "appearance",
      version: 1
    }
  )
);

export default useAppearanceState;
