import React, { useEffect } from "react";

interface Props {
  behavior?: ScrollBehavior;
}

const ScrollToTop: React.FC<Props> = ({ behavior = "auto" }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ left: 0, top: 0, behavior });
    }
  }, [behavior]);

  return null;
};

export default ScrollToTop;
