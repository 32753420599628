import Tooltip from "rc-tooltip";
import React, { useMemo } from "react";
import { Hotspot, HotspotLocation } from "../utils/types";

interface Props {
  className?: string;
  hotspot?: Hotspot;
  location?: HotspotLocation;
  withoutTooltip?: boolean;
}

const StatusDot: React.FC<Props> = ({
  className,
  hotspot,
  location,
  withoutTooltip,
  ...otherProps
}) => {
  const [outerColorClass, innerColorClass, tooltipText] = useMemo(() => {
    let text = "";

    if (hotspot) {
      text += `Hotspot is ${hotspot.active ? "active" : "inactive"}.`;
    }

    if (location) {
      text = "Location is used for reward scaling";
    }

    return hotspot && !hotspot.active
      ? ["bg-red-100 bg-opacity-50", "bg-red-400", text]
      : ["bg-green-100 bg-opacity-50", "bg-green-400", text];
  }, [hotspot, location]);

  const renderContent = useMemo(
    () => (
      <span
        className={`flex h-4 w-4 items-center justify-center rounded-full ${outerColorClass} ${
          className || ""
        }`}
        aria-hidden="true"
        {...otherProps}
      >
        <span className={`h-2 w-2 rounded-full ${innerColorClass}`} />
      </span>
    ),
    [className, innerColorClass, otherProps, outerColorClass]
  );

  if (withoutTooltip) return renderContent;

  return (
    <Tooltip placement="top" overlay={tooltipText}>
      {renderContent}
    </Tooltip>
  );
};

export default StatusDot;
