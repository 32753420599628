import GA4React from "ga-4-react";
import React from "react";
import ReactDOM from "react-dom";
import "react-phone-number-input/style.css";
import "react-widgets/styles.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.css";
import "./styles/mapbox.css";
import "./styles/popper.css";
import "./styles/rc-tooltip.css";
import "./styles/react-widgets.css";

const ga4react = new GA4React(
  process.env.REACT_APP_GA4_MEASUREMENT_ID as string
);

(async () => {
  try {
    if (ga4react) {
      await ga4react.initialize();
    }
  } catch (e) {
    console.error(e);
  }

  ReactDOM.render(<App />, document.getElementById("root"));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
