import animalHash from "angry-purple-tiger";
import { values } from "lodash";
import { stringifyUrl } from "query-string";
import React, { useEffect, useMemo } from "react";
import {
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  useHistory
} from "react-router-dom";
import DetailsPagination from "../../../components/DetailsPagination";
import NavLink from "../../../components/NavLink";
import StatusDot from "../../../components/StatusDot";
import { useHotspots } from "../../../context/hotspots";
import { useInstalls } from "../../../context/installs";
import { useWorkspaces } from "../../../context/workspaces";
import { avatarsApi } from "../../../utils/constants";
import useMapCenter, {
  Boundaries,
  Coordinates
} from "../../../utils/useMapCenter";
import { useShouldUpdateMapCentroid } from "../../../utils/useShouldCenterMap";
import InstallInfoTab from "./InstallInfoTab";

interface Props extends RouteComponentProps<{ id: string }> {
  isDark: boolean;
}

const InstallDetails: React.FC<Props> = (props) => {
  const { currentWorkspace } = useWorkspaces();
  const { installs, setCurrentlyEditedInstall } = useInstalls();
  const { hotspotsMap } = useHotspots();
  const setCenter = useMapCenter((state) => state.setCenter);
  const center = useMapCenter((state) => state.center);
  const boundaries = useMapCenter((state) => state.boundaries);
  const history = useHistory();

  const currentInstall = useMemo(
    () => (props.match.params.id ? installs[props.match.params.id] : undefined),
    [installs, props.match.params.id]
  );

  const currentHotspot = useMemo(
    () =>
      currentInstall?.hotspot_uuid
        ? hotspotsMap[currentInstall?.hotspot_uuid]
        : undefined,
    [currentInstall?.hotspot_uuid, hotspotsMap]
  );

  const mapCentroidConfig: {
    selectedItem: {
      id: string;
      coordinates: Coordinates;
    };
    mapBoundaries?: Boundaries;
    currentMapCentroid: Coordinates;
  } = useMemo(() => {
    let pointDetails = { lng: 0, lat: 0, id: "" };

    if (currentInstall) {
      const installedHotspot = hotspotsMap[currentInstall.hotspot_uuid];

      if (installedHotspot?.lat) {
        const { id, lat, lng } = installedHotspot;
        pointDetails = { lat, lng, id };
      }
    }

    return {
      selectedItem: {
        coordinates: [pointDetails.lng, pointDetails.lat],
        id: pointDetails.id
      },
      currentMapCentroid: center,
      mapBoundaries: boundaries
    };
  }, [boundaries, center, currentInstall, hotspotsMap]);

  const mapCentroid = useShouldUpdateMapCentroid(mapCentroidConfig);

  useEffect(() => {
    setCurrentlyEditedInstall(undefined);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCenter(mapCentroid);
  }, [mapCentroid, setCenter]);

  const tabs = useMemo(() => [{ key: "info", title: "Info", count: 0 }], []);

  const detailsPageMatch = matchPath<{
    id: string;
    section: string;
  }>(props.location.pathname, {
    path: `/installs/:id/:section`
  });

  return (
    <>
      <div className="pb-6">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="-mt-12 sm:-mt-16">
            <div className="flex justify-between">
              <img
                className="h-24 w-24 sm:h-32 sm:w-32 z-10 cursor-pointer"
                src={stringifyUrl({
                  url: `${avatarsApi}/bottts/${currentInstall?.hotspot_uuid}.svg`,
                  query: { w: 128, h: 128 }
                })}
                alt="Hotspot avatar"
              />
              <div className="flex items-center">
                <DetailsPagination
                  path="installs"
                  selectedItemUuid={currentInstall?.uuid || ""}
                  data={values(installs)}
                  onClose={() =>
                    history.push(`/contacts/${currentWorkspace?.uuid}/info)`)
                  }
                  idKey="uuid"
                />
              </div>
            </div>
            <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="mt-6 min-w-0 flex-1">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    {currentInstall ? (
                      <h1 className="text-md sm:text-2xl font-bold text-gray-900 dark:text-white truncate inline-flex items-center">
                        {animalHash(currentInstall?.hotspot_uuid)}
                        <StatusDot
                          className="ml-3 mt-1"
                          hotspot={currentHotspot}
                        />
                      </h1>
                    ) : (
                      <span className="flex w-10 h-6 animate-pulse bg-gray-400 rounded sm:w-40 mt-2" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="sm:border-b sm:border-gray-200 dark:border-gray-500 overflow-auto whitespace-nowrap">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <select
                className="sm:hidden block w-full pl-3 py-2 sm:text-sm rounded-md dark:bg-gray-800"
                onChange={(e) => {
                  props.history.push(`${props.match.url}/${e.target.value}`);
                }}
                value={detailsPageMatch?.params.section}
              >
                {tabs.map(({ key, title }) => (
                  <option key={key} value={key}>
                    {title}
                  </option>
                ))}
              </select>
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map(({ key, title, count }) => (
                    <NavLink
                      key={key}
                      to={`${props.match.url}/${key}`}
                      activeClassName="border-pink-500 text-gray-900 dark:text-white dark:text-white dark:border-white"
                      inactiveClassName="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300"
                      className="whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                    >
                      <span>{title}</span>
                      {!!count && (
                        <span className="bg-gray-200 text-gray-500 group-hover:text-gray-700 dark:text-gray-200 dark:bg-gray-600 dark:group-hover:text-gray-200 dark:group-hover:bg-gray-500 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                          {count}
                        </span>
                      )}
                    </NavLink>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <Switch>
          <Route
            path={`${props.match.path}/info`}
            component={InstallInfoTab as any}
          />

          <Redirect to={`${props.match.path}/info`} />
        </Switch>
      </div>
    </>
  );
};

export default InstallDetails;
