import clipboardCopy from "clipboard-copy";
import Tooltip from "rc-tooltip";
import React, { useEffect, useMemo, useState } from "react";
import CheckIcon from "../components/icons/CheckIcon";
import CopyIcon from "../components/icons/CopyIcon";
import classNames from "../utils/classNames";

interface Props {
  className?: string;
  text: string;
  label?: string;
}

const Copy: React.FC<Props> = ({ className, text, label, ...otherProps }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    }

    return () => {
      if (timer != null) {
        clearTimeout(timer);
      }
    };
  }, [isCopied]);

  const Icon = useMemo(() => (isCopied ? CheckIcon : CopyIcon), [isCopied]);

  return (
    <Tooltip
      placement="top"
      overlay={`${isCopied ? "Copied" : "Copy"} ${label || text}`}
    >
      <Icon
        onClick={async () => {
          await clipboardCopy(text);
          setIsCopied(true);
        }}
        className={classNames(
          "text-gray-400",
          !isCopied &&
            "hover:text-gray-500 dark:hover:text-white cursor-pointer",
          className
        )}
        {...otherProps}
      />
    </Tooltip>
  );
};

export default Copy;
